export const FORM_FIELD_WIDTH = 300;

export const TOTALS_COLUMN_WIDTH = 150;

export const MONEY_FORM_FIELD_WIDTH = 185;

export const NUMBER_FORM_FIELD_WIDTH = 1;

export const PERCENTAGE_FORM_FIELD_WIDTH = 200;

export const ADDRESS_FIELD_WIDTH = 275;

export const CATEGORY_FIELD_WIDTH = 300;

export const PARTNER_FIELD_WIDTH = 300;

export const DELIVERY_FILTER_ALL = "ALL";

export const DELIVERY_FILTER_NEEDS_SCHEDULING = "NEEDS_SCHEDULING";

export const DELIVERY_FILTER_SCHEDULED = "SCHEDULED";

export const DELIVERY_FILTER_DELIVERED = "DELIVERED";

export const DATE_SELECTOR_HEIGHT = 43;

export const WALK_IN_CUSTOMER = "Cash Customer";

export const DEFAULT_ROWS_PER_PAGE = 25;

// Card processing times out in 60 seconds, the user can interact with the terminal to extend another 60 seconds.
// Add on another 20 seconds should allow this to return to our api request.
export const PAYMENT_PROCESSING_TIMEOUT_IN_SECONDS = 140;

export const PAYMENT_PROCESSING_POLLING_INTERVAL_IN_MILLISECONDS = 500;

export const PURCHASE_ORDER_POLLING_INTERVAL_SECONDS = 30 * 1000;

export const NULL_VALUE_SELECTOR_KEY = "---";

export const SALES_ORDER_TO_PURCHASE_ORDER_ALL_ITEMS = "all";
export const SALES_ORDER_TO_PURCHASE_ORDER_SPECIAL_ITEMS = "special";
