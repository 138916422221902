import numeral, { Numeral } from 'numeral';
import Big from "big.js";
import FEMicroMoney from './FEMicroMoney';

export default class FEMoney {

  static readonly microsToDollars = 1000000;

  static readonly SCALE = 2;

  static readonly ROUNDING_MODE = Big.roundHalfUp;

  static readonly ZERO = new FEMoney(new Big(0));

  readonly mAmount: Big;

  readonly bigAmount: Big;

  readonly amountAsString: string;

  private constructor(big: Big) {
    this.bigAmount = big;
    this.mAmount = big.round(FEMoney.SCALE, FEMoney.ROUNDING_MODE);
    this.amountAsString = this.mAmount.toString();
  }

  static fromBig(amount: Big): FEMoney {
    return new FEMoney( amount);
  }

  /**
   * Initialize money from floating point format of number.  Ex 0.12 = $.12
   * @param amount
   */
  static fromDollarDouble(amount: number): FEMoney {
    return new FEMoney(new Big(amount).round(FEMoney.SCALE, FEMoney.ROUNDING_MODE));
  }

  /**
   * One micro is 1/1,000,000 of a dollar.  Ex.  10000 = $.01
   * @param amount
   */
  static fromMicros(amount: number): FEMoney {
    return this.fromBig(new Big(amount).div( FEMoney.microsToDollars));
  }

  static fromString(amount: string): FEMoney {
    const commaStripped = amount.replace(/,/g, '');
    return FEMoney.fromDollarDouble(Number(commaStripped));
  }

  static min(amount1: FEMoney, amount2: FEMoney): FEMoney {
    if (amount1.cmp(amount2) < 0) {
      return amount1;
    } else {
      return amount2;
    }
  }

  static max(amount1: FEMoney, amount2: FEMoney): FEMoney {
    if (amount1.cmp(amount2) > 0) {
      return amount1;
    } else {
      return amount2;
    }
  }

  add(money: FEMoney): FEMoney {
    return money != null ? FEMoney.fromBig( this.mAmount.add(money.mAmount)) : this;
  }

  subtract(money: FEMoney): FEMoney {
    return money != null ? FEMoney.fromBig(this.mAmount.minus(money.mAmount)) : this;
  }

  equals(money: FEMoney): boolean {
    return money != null ? this.mAmount.eq(money.mAmount) : false;
  }

  divide(divisor: number): FEMoney {
    return divisor != 0 ? FEMoney.fromBig(this.mAmount.div(divisor)) : this;
  }

  // Perform the math calculation on the original Big amount
  divideByMoney(divisor: FEMoney): FEMoney {
    if (divisor.bigAmount.eq(0)) return FEMoney.ZERO;
    
    const bigDividedByBig = this.bigAmount.div(divisor.bigAmount);
    return FEMoney.fromBig(bigDividedByBig);    
  }

  // Perform the math calculation on the original Big amount
  multiplyByMoney(multiplier: FEMoney): FEMoney {
    const bigMultipliedByBig = this.bigAmount.mul(multiplier.bigAmount);
    return FEMoney.fromBig(bigMultipliedByBig);
  }

  multiply(multiplier: number): FEMoney {
    return FEMoney.fromBig(this.mAmount.mul(multiplier));
  }

  isNegative(): boolean {
    return this.compareTo(0) < 0;
  }

  //Returns 0 of equal, negative if less and positive if greater
  compareTo(number :number): number {
    return this.mAmount.toNumber() - number;
  }

  // Same as compareTo but for comparing two FEMoney values
  cmp(other: FEMoney): number {
    return this.bigAmount.cmp(other.bigAmount);
  }

  toDisplayString(includeDollarSign:boolean = true): string
  {
    const moneyFormat = includeDollarSign ? '$0,0.00' : '0,0.00';
    const defaultZero = includeDollarSign ? '$0.00' : '0.00';

    const format = this.mAmount ? numeral(this.mAmount.round(FEMoney.SCALE).toNumber()).format(moneyFormat) : defaultZero;
    return format;
  }

  // Use this when sending values to the backend
  toMicros(): number
  {
    return this.mAmount.mul(FEMoney.microsToDollars).round(0).toNumber();
  }

  toMicrosAsString(): string
  {
    return this.toMicros().toString();
  }

  toString(): string {
    return this.toDisplayString();
  }

  toMoneyAsNumber(): number {
    return this.mAmount.toNumber();
  }

  static fromMicroMoney(money: FEMicroMoney): FEMoney {
    return money != null ? FEMoney.fromBig(money.mmAmount) : FEMoney.ZERO;
  }
}