// @mui
import { Stack, Box } from '@mui/material';
// config
import { NAV } from '../../../config';
// utils
import { hideScrollbarX } from '../../../utils/cssStyles';
// components
import Logo from '../../../components/logo';
import { NavSectionMini } from '../../../components/nav-section';
//
import NotificationsPopover from '../header/NotificationsPopover';
import AccountPopover from '../header/AccountPopover';
import { useAuthContext } from 'src/auth/useAuthContext';
import { FRHFAppUser } from 'src/@state/form/fAppUser';
import { navConfig, posRoleNavConfig } from './erpConfig';
import { POINT_OF_SALE_ROLE } from 'src/@auth/FRoles';

// ----------------------------------------------------------------------

export default function NavMini() {
  const { user } = useAuthContext();
  const appUser:FRHFAppUser = user as FRHFAppUser;
  
  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_DASHBOARD_MINI },
      }}
    >
      <Stack
        sx={{
          pb: 2,
          height: 1,
          position: 'fixed',
          width: NAV.W_DASHBOARD_MINI,
          borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          ...hideScrollbarX,
        }}
      >
        {/* Left Navigation */}
        <Logo sx={{ mx: 'auto', my: 2 }} />

        {/* <Box
          component="div"
          sx={{
            width: 40,
            height: 40,
            display: 'inline-flex',
            mx: 'auto',
            mb: 1
          }}
        >        
          <NotificationsPopover />
        </Box> */}

        <Box
          component="div"
          sx={{
            width: 40,
            height: 40,
            display: 'inline-flex',
            mx: 'auto',
            mb: 3
          }}
        >        
          <AccountPopover />
        </Box>

        {/* <NavSectionMini data={navConfig} /> */}
        <NavSectionMini data={appUser.rhfAccount.rhfRole === POINT_OF_SALE_ROLE ? posRoleNavConfig : navConfig} />
      </Stack>
    </Box>
  );
}
