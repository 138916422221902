import numeral, { Numeral } from 'numeral';
import FEMicroMoney from 'src/@model/FEMicroMoney';
import FEMoney from 'src/@model/FEMoney';

// ----------------------------------------------------------------------

type InputValue = string | number | null | undefined;

const microsToDollars = 1000000;

export function fNumber(number: InputValue) {
  return numeral(number).format();
}

export function fCurrency(number: InputValue) {
  const format = number ? numeral(number).format('$0,0.00') : '';

  return result(format, '.00');
}

export function fMoney(number: InputValue, includeDollarSign:boolean = true) {
  const moneyFormat = includeDollarSign ? '$0,0.00' : '0,0.00';
  const defaultZero = includeDollarSign ? '$0.00' : '0.00';

  const format = number ? numeral(number).divide(microsToDollars).format(moneyFormat) : defaultZero;
  return format;
}

export function fMicroMoney(number: InputValue, includeDollarSign:boolean = true) {
  const microMoneyFormat = includeDollarSign ? '$0,0.00[00]' : '0,0.00[00]';
  const defaultZero = includeDollarSign ? '$0.00' : '0.00';

  const format = number ? numeral(number).divide(microsToDollars).format(microMoneyFormat) : defaultZero;
  return format;
}

export function fPercent(number: InputValue) {
  const format = number ? numeral(Number(number) / 100).format('0.00%') : '';

  return result(format, '.00');
}

export function fData(number: InputValue) {
  const format = number ? numeral(number).format('0.0 b') : '';

  return result(format, '.0');
}

function result(format: string, key = '.00') {
  const isInteger = format.includes(key);

  return isInteger ? format.replace(key, '') : format;
}


// FTaxRateTextField
export function formatInputToPercent(value: InputValue) {
  return Number(numeral(value).divide(100).value());
}

export function formatPercentAsString(percent: string | number) {
  return numeral(Number(percent) * 100).format('0.000');
}

export function formatQuantity(qty:Numeral | number | undefined, isReadOnly?:boolean):string {
  return isReadOnly ? numeral(qty).format('0,0.000') : numeral(qty).format('0.000');
}

export function formatPhoneNumber(phoneNumber:string|undefined|null):string {
  if (phoneNumber === undefined || phoneNumber === null) return '';
  if (phoneNumber.length !== 10) return phoneNumber;

  if (phoneNumber.length === 10) {
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6)}`;
  }
  return phoneNumber;
}

export function formatNullableFEMoney(value: FEMoney|null|undefined, errorMessage:string = "$0.00"):string {
  if ( value === null || value === undefined ) return errorMessage;
  return value.toDisplayString();
}

export function formatNullableFEMicroMoney(value: FEMicroMoney|null|undefined, errorMessage:string = "$0.00", useMicroMoneyFormat:boolean = false):string {
  if ( value === null || value === undefined ) return errorMessage;
  return useMicroMoneyFormat ? value.toDisplayString() : value.toMoneyDisplayString();
}
