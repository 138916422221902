import { createSlice } from '@reduxjs/toolkit';
// utils
import { IBranch, IUserProfileState } from 'src/@types/userProfile';

// ----------------------------------------------------------------------

const initialState: IUserProfileState = {
  isLoading: false,
  error: null,
  allBranches: [],
  currentBranch: null,
  currentInvoiceTab: 'all',
};

const slice = createSlice({
  name: 'userProfileReduxKey',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    addAllBranches(state, action) {
      const branches:IBranch[] = action.payload;
      state.allBranches = branches;
    },

    updateBranchInfo(state, action) {
      const revisedBranch:IBranch = action.payload;
      const updatedBranches = state.allBranches.map((branch) => {
        if (branch.rBranchId === revisedBranch.rBranchId) {
          return {
            ...branch,
            rBranchName: revisedBranch.rBranchName,
            rTaxRate: revisedBranch.rTaxRate
          };
        }
        return branch;
      });

      state.allBranches = updatedBranches;
    },

    addBranchToUserProfile(state, action) {
      const branchId = action.payload;

      const currentBranch = state.allBranches.find(b => b.rBranchId === branchId);
      if (currentBranch) {
        state.currentBranch = currentBranch;
      }
    },

    updateInvoiceTab(state, action) {
      state.currentInvoiceTab = action.payload;
    }

  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  addAllBranches,
  updateBranchInfo,
  addBranchToUserProfile,
  updateInvoiceTab,
} = slice.actions;
