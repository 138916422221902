import { FPartnerStatusEnum, FPartnerTypeEnum, FAddress, FContact, FJob, FBranch, FPartner, FCategory, FAccount, FPartnerSummary, FUnit, FUnitInput, FEntityReference, FEntityTypeEnum, FOrganization, FPartnerInput, FAddressInput, FContactInput, FCustomerTaxExemptionInput, FCustomerTaxExemption, FJobInput, FTerminal, FTerminalInput, FBranchInput, FImportExportType, FTaxAccount } from "src/generated/graphql";
import { DeepPartial } from "./fDeepPartial";

// ----------------------------------------------------------------------

import { FRHFAsset } from "./fAssets";
import FEMoney from "src/@model/FEMoney";
import FEPercentage from "src/@model/FEPercentage";
import { chargeAccountBEtoFE } from "./fChargeAccountForm";
import { FRHFChargeAccount, initialChargeAccountState } from "./fChargeAccount";
import { FRHFPricingLevel } from "./fPricing";
import { pricingLevelBEtoFE } from "./fPricingForm";
import { NULL_VALUE_SELECTOR_KEY } from "src/@components/@utils/constants";
import {cashDrawerBEtoFE, FRHFCashDrawer} from "./fCashDrawer";
import {CREATE_NEW_ADDRESS_ID} from "../../@sections/org/FDeliveryAddressSelectorSimple";

export interface FRHFOrganization {
  rhfOrganizationId: string;
  rhfName: string;
  rhfWebsiteUrl: string;
  rhfContactEmail: string;
  rhfLogoAsset: FRHFAsset | null;
}

export const initialOrganizationState: FRHFOrganization = {
  rhfOrganizationId: "",
  rhfName: "",
  rhfWebsiteUrl: "",
  rhfContactEmail: "",
  rhfLogoAsset: null,
}


export function organizationBEtoFE(org:DeepPartial<FOrganization | null | undefined>):FRHFOrganization {
  return {
    rhfOrganizationId: org?.organizationId ?? "",
    rhfName: org?.name ?? "",
    rhfWebsiteUrl: org?.websiteUrl ?? "",
    rhfContactEmail: org?.contactEmail ?? "",
    rhfLogoAsset: {
      rhfS3FileId: org?.logo?.fileId ?? "",
      rhfS3Url: org?.logo?.downloadUrl ?? "",
      rhfS3File: null,
      rhfS3FilePreview: null,
    }  }
}

export interface FRHFAccount {
  rhfAccountId: string;
  rhfDefaultBranch?: FRHFBranch;
  rhfCashDrawer?: FRHFCashDrawer;
  rhfEmail: string;
  rhfFirstName?: string;
  rhfLastName?: string;
  rhfOrganizationId: string;
  rhfOrganizationName: string;
  rhfRole: string;
  rhfDecryptedPIN?: string;
}

export function accountBEtoFE(account:DeepPartial<FAccount | null | undefined>):FRHFAccount {
  return {
    rhfAccountId: account?.accountId ?? "",
    rhfDefaultBranch: branchBEtoFE(account?.defaultBranch),
    rhfCashDrawer: cashDrawerBEtoFE(account?.cashDrawer),
    rhfEmail: account?.email ?? "",
    rhfFirstName: account?.firstName ?? "",
    rhfLastName: account?.lastName ?? "",
    rhfOrganizationId: account?.organization?.organizationId ?? "",
    rhfOrganizationName: account?.organization?.name ?? "",
    rhfRole: account?.role ?? "",
    rhfDecryptedPIN: account?.decryptedPINPlainText ?? "",
  }
}

// UoM
export interface FRHFUnitOfMeasure {
  rhfUnitId: string;
  rhfAbbreviation: string;
  rhfName: string;
}

export function unitOfMeasureBEtoFE(uom:DeepPartial<FUnit | null | undefined>):FRHFUnitOfMeasure {
  return {
    rhfUnitId: uom?.unitId ?? "",
    rhfAbbreviation: uom?.abbreviation ?? "",
    rhfName: uom?.name ?? "",
  }
}

export const EACH_UOM:FRHFUnitOfMeasure = {
  rhfUnitId: "1",
  rhfAbbreviation: "ea",
  rhfName: ""
}

// Branch
export interface FRHFBranch {
  rhfBranchId: string;
  rhfBranchName: string;
  rhfTaxAccount: FRHFTaxAccount|undefined;
  rhfDefaultTaxRate: FEPercentage;
  rhfPhoneNumber?: string;
  rhfAddress?: FRHFAddress;
}

export function branchBEtoFE(branch:DeepPartial<FBranch | null | undefined>):FRHFBranch {
  return {
    rhfBranchId: branch?.branchId ?? "",
    rhfBranchName: branch?.name ?? "",
    rhfTaxAccount: taxAccountBEtoFE(branch?.taxAccount),
    rhfDefaultTaxRate: branch?.defaultTaxRate ? FEPercentage.fromDecimal(branch.defaultTaxRate) : FEPercentage.ZERO,
    rhfPhoneNumber: branch?.phoneNumber ?? "",
    rhfAddress: addressBEtoFE(branch?.primaryAddress),
  }
}

export function branchFEtoBE(branch:FRHFBranch):FBranchInput {
  return {
    branchId: branch.rhfBranchId,
    name: branch.rhfBranchName,
    phoneNumber: branch.rhfPhoneNumber,
    defaultTaxRate: branch.rhfDefaultTaxRate.toDecimal(),
    taxAccountId: branch?.rhfTaxAccount?.rhfTaxAccountId,
  }
}

export interface FRHFCategory {
  rhfParentCategoryId: string;
  rhfCategoryId: string;
  rhfCategoryName: string;
  rhfCategoryLevel: number;
}

export function categoryBEtoFE(category:Partial<FCategory | null | undefined>):FRHFCategory {
  return {
    rhfParentCategoryId: category?.parentCategory?.categoryId ?? "",
    rhfCategoryId: category?.categoryId ?? "",
    rhfCategoryName: category?.name ?? "",
    rhfCategoryLevel: category?.level ?? 0,
  }
}

export interface FRHFPartner {
  rhfName: string;
  rhfPartnerId: string;
  rhfStatus: FPartnerStatusEnum;
  rhfType: FPartnerTypeEnum;
  rhfPrimaryAddress?: FRHFAddress | null;
  rhfPrimaryContact?: FRHFContact | null;
  rhfContacts?: FRHFContact[];
  rhfAddresses?: FRHFAddress[];
  rhfTaxExempt?: boolean;
  rhfCustomerTaxExemption?: FRHFCustomerTaxExemption;
  rhfChargeAccount?: FRHFChargeAccount;
  rhfPayToPartnerId?: string 
  rhfPayForPartnerIds?: string[]
  rhfPricingLevel?: FRHFPricingLevel;
  rhfInternalNotesCount?: number;
}

export const initialVendorState: FRHFPartner = {
  rhfName: "",
  rhfPartnerId: "",
  rhfStatus: FPartnerStatusEnum.Active,
  rhfType: FPartnerTypeEnum.Vendor,
  rhfContacts: [],
  rhfAddresses: [],
}

export function vendorBEtoFE(partner:DeepPartial<FPartner | null | undefined>):FRHFPartner {
  return {
    rhfName: partner?.name ?? "",
    rhfPartnerId: partner?.partnerId ?? "",
    rhfStatus: partner?.status ?? FPartnerStatusEnum.Active,
    rhfType: partner?.type ?? FPartnerTypeEnum.Vendor,
    rhfPrimaryAddress: addressBEtoFE(partner?.primaryAddress),
    rhfPrimaryContact: contactBEtoFE(partner?.primaryContact),
    rhfContacts: contactsBEtoFE(partner?.contacts),
    rhfAddresses: addressesBEtoFE(partner?.addresses),
    rhfPayToPartnerId: partner?.payToPartnerId ?? undefined,
    rhfPayForPartnerIds: partner?.payForPartnerIds ? payForPartnerIdsBEtoFE(partner.payForPartnerIds) : [],
  }
}

function payForPartnerIdsBEtoFE(payForPartnerIds:DeepPartial<string|null|undefined>[]):string[] {
  return payForPartnerIds.map((payForPartnerId) => payForPartnerId ?? "0");
}

export function vendorFEtoBE(vendor:FRHFPartner):FPartnerInput {
  return {
    partnerId: vendor.rhfPartnerId !== "" ? vendor.rhfPartnerId : undefined,
    name: vendor.rhfName,
    status: vendor.rhfStatus,
    type: vendor.rhfType,
    payToPartnerId: vendor.rhfPayToPartnerId,
  }
}

export const initialCustomerState: FRHFPartner = {
  rhfName: "",
  rhfPartnerId: "",
  rhfStatus: FPartnerStatusEnum.Active,
  rhfType: FPartnerTypeEnum.Customer,
  rhfContacts: [],
  rhfAddresses: [],
  rhfTaxExempt: false,
  rhfChargeAccount: initialChargeAccountState,
}

export function customerBEtoFE(customer:DeepPartial<FPartner | null | undefined>):FRHFPartner {
  return {
    rhfName: customer?.name ?? "",
    rhfPartnerId: customer?.partnerId ?? "",
    rhfStatus: customer?.status ?? FPartnerStatusEnum.Active,
    rhfType: customer?.type ?? FPartnerTypeEnum.Customer,
    rhfPrimaryAddress: addressBEtoFE(customer?.primaryAddress),
    rhfPrimaryContact: contactBEtoFE(customer?.primaryContact),
    rhfContacts: contactsBEtoFE(customer?.contacts),
    rhfAddresses: addressesBEtoFE(customer?.addresses),
    rhfTaxExempt: customer?.taxExempt ?? false,
    rhfCustomerTaxExemption: taxExemptionBEtoFE(customer?.customerTaxExemption),
    rhfChargeAccount: chargeAccountBEtoFE(customer?.chargeAccount),
    rhfPricingLevel: pricingLevelBEtoFE(customer?.pricingLevel),
    rhfInternalNotesCount: customer?.internalNotesCount ?? 0
  }
}

export function customerFEtoBE(customer:FRHFPartner):FPartnerInput {
  return {
    partnerId: customer.rhfPartnerId !== "" ? customer.rhfPartnerId : undefined,
    name: customer.rhfName,
    status: customer.rhfStatus,
    type: customer.rhfType,
    taxExempt: customer.rhfTaxExempt,
    pricingLevelId: customer.rhfPricingLevel?.rhfPricingLevelId === NULL_VALUE_SELECTOR_KEY ? undefined : customer.rhfPricingLevel?.rhfPricingLevelId,
  }
}

// Point of Sale
export interface FRHFPOSCustomer extends FRHFPartner {
  jobOptions?: FRHFJob[];
  addressOptions?: FRHFAddress[];
  contactOptions?: FRHFContact[];
  pricingTier?: string;
}

export function posCustomerBEtoFE(customer:DeepPartial<FPartner | null | undefined>):FRHFPOSCustomer {
  return {
    ...customerBEtoFE(customer),
    jobOptions: jobsBEtoFE(customer?.jobs ?? []),
    addressOptions: addressesBEtoFE(customer?.addresses ?? []),
    contactOptions: contactsBEtoFE(customer?.contacts ?? []),
  }
}

export interface FRHFContact {
  rhfContactId: string;
  rhfName: string;
  rhfEmail: string;
  rhfPhoneNumber: string;
  rhfPosition: string;
  rhfPrimary: boolean;
}

export function contactBEtoFE(contact:DeepPartial<FContact | null | undefined>):FRHFContact|undefined {
  if (contact === null || contact === undefined) { return undefined }

  return {
    rhfContactId: contact?.contactId ?? "",
    rhfName: contact?.name ?? "",
    rhfEmail: contact?.email ?? "",
    rhfPhoneNumber: contact?.phoneNumber ?? "",
    rhfPosition: contact?.position ?? "",
    rhfPrimary: contact?.primary ?? false,
  }
}

export function contactFEtoBE(contact:FRHFContact):FContactInput {
  return {
    contactId: contact.rhfContactId,
    name: contact.rhfName,
    email: contact.rhfEmail,
    phoneNumber: contact.rhfPhoneNumber,
    position: contact.rhfPosition,
    primary: contact.rhfPrimary,
  }
}

export function contactsBEtoFE(contacts:DeepPartial<FContact[] | null | undefined>):FRHFContact[] {
  const feContacts:FRHFContact[] = [];
  contacts?.forEach((contact) => {
    const feContact = contactBEtoFE(contact);
    if (feContact) { feContacts.push(feContact) }
  })
  return feContacts;
}

// Job
export const initialJobState: FRHFJob = {
  rhfJobId: "",
  rhfPartnerId: "",
  rhfName: "",
  rhfAddresses: [],
  rhfContacts: [],
  rhfTaxExempt: false,
  rhfDefaultJob: false
}

export interface FRHFJob {
  rhfJobId: string;
  rhfPartnerId: string;
  rhfName: string;
  rhfAddresses?: FRHFAddress[];
  rhfContacts?: FRHFContact[];
  rhfTaxExempt?: boolean;
  rhfPricingLevel?: FRHFPricingLevel;
  rhfDefaultJob: boolean;
}

export function jobFEtoBE(customerId:string,job:FRHFJob):FJobInput {
  return {
    jobId: job.rhfJobId,
    partnerId: customerId,
    name: job.rhfName,
    taxExempt: job.rhfTaxExempt ?? false,
    pricingLevelId: job.rhfPricingLevel?.rhfPricingLevelId,
  }
}

export function jobBEtoFE(job:DeepPartial<FJob> | null | undefined):FRHFJob {
  return {
    rhfJobId: job?.jobId ?? "-",
    rhfPartnerId: job?.partner?.partnerId ? job.partner.partnerId : "",
    rhfName: job?.name ?? "",
    rhfAddresses: job?.addresses ? addressesBEtoFE(job.addresses) : [],
    rhfContacts: job?.contacts ? contactsBEtoFE(job.contacts) : [],
    rhfTaxExempt: job?.taxExempt ?? false,
    rhfPricingLevel: pricingLevelBEtoFE(job?.pricingLevel),
    rhfDefaultJob: job?.defaultJob ?? false
  }
}

export function jobsBEtoFE(jobs:DeepPartial<FJob[] | null | undefined>):FRHFJob[] {
  const feJobs:FRHFJob[] = [];
  jobs?.forEach((job) => {
    const feJob = jobBEtoFE(job);
    if (feJob) { feJobs.push(feJob) }
  })
  return feJobs;
}

// Address
export interface FRHFAddress {
  rhfOrganizationId: string;
  rhfPartnerId: string | null;
  rhfJobId: string | null;
  rhfPrimary: boolean;
  rhfAddressId: string;
  rhfStreetLine1: string;
  rhfStreetLine2: string;
  rhfCity: string;
  rhfState: string;
  rhfPostalCode: string;
  rhfCountryCode: string;
}

export function addressBEtoFE(address:DeepPartial<FAddress | null | undefined>):FRHFAddress {
  return {
    rhfOrganizationId: address?.organizationId ?? "",
    rhfPartnerId: null,
    rhfJobId: null,
    rhfPrimary: address?.primary ?? false,
    rhfAddressId: address?.addressId ?? "",
    rhfStreetLine1: address?.streetLine1 ?? "",
    rhfStreetLine2: address?.streetLine2 ?? "",
    rhfCity: address?.city ?? "",
    rhfState: address?.state ?? "",
    rhfPostalCode: address?.postalCode ?? "",
    rhfCountryCode: address?.countryCode ?? "",
  }
}

export function addressesBEtoFE(addresses:DeepPartial<FAddress[] | null | undefined>):FRHFAddress[] {
  const feAddresses:FRHFAddress[] = [];
  addresses?.forEach((address) => {
    const feAddress = addressBEtoFE(address);
    if (feAddress) { feAddresses.push(feAddress) }
  })
  return feAddresses;
}

export function addressFEtoBE(address:FRHFAddress|undefined):FAddressInput {
  return {
    primary: address?.rhfPrimary ?? false,
    addressId: address?.rhfAddressId == CREATE_NEW_ADDRESS_ID ? null : address?.rhfAddressId,
    streetLine1: address?.rhfStreetLine1,
    streetLine2: address?.rhfStreetLine2,
    city: address?.rhfCity,
    state: address?.rhfState,
    postalCode: address?.rhfPostalCode,
    countryCode: address?.rhfCountryCode,
  }
}

// Job
export interface FRHFEntity {
  rhfEntityId: string;
  rhfEntityName: string;
  rhfEntityType: string;
}

export function entityBEtoBE(entity:DeepPartial<FEntityReference | null | undefined>):FEntityReference {
  return {
    entityId: entity?.entityId ?? "",
    entityName: entity?.entityName ?? "",
    entityType: entity?.entityType ?? FEntityTypeEnum.Product,
  }
}

export interface FRHFS3Attachment {
  rhfS3FileId: string | null;
  rhfS3Url: string | null;
}

export interface FRHFCustomerTaxExemption {
  rhfCustomerTaxExemptionId: string;
  rhfTaxId: string;
  rhfResellerId: string;
}

export function taxExemptionBEtoFE(taxExemption:DeepPartial<FCustomerTaxExemption | null | undefined>):FRHFCustomerTaxExemption|undefined {
  if (taxExemption === null || taxExemption === undefined) { return undefined; }
  
  return {
    rhfCustomerTaxExemptionId: taxExemption?.customerTaxExemptionId ?? "",
    rhfTaxId: taxExemption?.taxId ?? "",
    rhfResellerId: taxExemption?.resellerId ?? "",
  }
}

export function taxExemptionFEtoBE(customer:FRHFPartner):FCustomerTaxExemptionInput|null {
  if (customer.rhfCustomerTaxExemption?.rhfTaxId === "") { return null; }

  return {
    customerTaxExemptionId: customer.rhfCustomerTaxExemption?.rhfCustomerTaxExemptionId ?? "",
    taxId: customer.rhfCustomerTaxExemption?.rhfTaxId ?? "",
    resellerId: customer.rhfCustomerTaxExemption?.rhfResellerId ?? "",
  }
}

export interface FRHFTerminal {
  rhfTerminalId: string;
  rhfTerminalName: string;
  rhfPrinterIpAddress: string;
  rhfPrinterPort: number;
  rhfPrinterStatusMessage: string[];
}

export const initialTerminalState: FRHFTerminal = {
  rhfTerminalId: "",
  rhfTerminalName: "",
  rhfPrinterIpAddress: "",
  rhfPrinterPort: 8043,
  rhfPrinterStatusMessage: [],
}

export function terminalBEtoFE(terminal:DeepPartial<FTerminal> | null | undefined):FRHFTerminal {
  return {
    rhfTerminalId: terminal?.terminalId ?? "",
    rhfTerminalName: terminal?.terminalName ?? "",
    rhfPrinterIpAddress: terminal?.printerIpAddress ?? "",
    rhfPrinterPort: terminal?.printerPort ?? 8043,
    rhfPrinterStatusMessage:  [],
  }
}

export function terminalFEtoBE(branch: FRHFBranch, terminal:FRHFTerminal):FTerminalInput {
  return {
    branchId: branch.rhfBranchId,
    terminalId: terminal.rhfTerminalId !== "" ? terminal.rhfTerminalId : undefined,
    terminalName: terminal.rhfTerminalName,
    printerIpAddress: terminal.rhfPrinterIpAddress,
    printerPort: terminal.rhfPrinterPort,
  }
}

export interface FRHFImportExport {
  rhfImportExportId: string;
  rhfImportExportType: FImportExportType|null;
}

export const initialImportExportState: FRHFImportExport = {
  rhfImportExportId: "",
  rhfImportExportType: null
}

export interface FRHFImportExportForm {
  rhfImportExport: FRHFImportExport
}

export const initialImportExportForm: FRHFImportExportForm = {
  rhfImportExport: initialImportExportState
}

export interface FRHFTaxAccount {
  rhfTaxAccountId: string;
  rhfTaxAgency: string;
  rhfDefaultTaxRate: FEPercentage;
}

export const initialTaxAccountState: FRHFTaxAccount = {
  rhfTaxAccountId: "",
  rhfTaxAgency: "",
  rhfDefaultTaxRate: FEPercentage.ZERO
}

export function taxAccountBEtoFE(taxAccount:DeepPartial<FTaxAccount | null | undefined>):FRHFTaxAccount|undefined {
  if (!taxAccount) { return undefined; }

  return {
    rhfTaxAccountId: taxAccount.taxAccountId ?? "",
    rhfTaxAgency: taxAccount.taxAgency ?? "",
    rhfDefaultTaxRate: taxAccount.defaultTaxRate ? FEPercentage.fromDecimal(taxAccount.defaultTaxRate) : FEPercentage.ZERO
  }
}

export function taxAccountFEtoBE(taxAccount:FRHFTaxAccount):FTaxAccount {
  return {
    taxAccountId: taxAccount.rhfTaxAccountId,
    taxAgency: taxAccount.rhfTaxAgency,
    defaultTaxRate: taxAccount.rhfDefaultTaxRate.toDecimal()
  }
}