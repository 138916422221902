import { FEntityTypeEnum, useFilesQuery } from "src/generated/graphql"
import { Box, List, ListItem, Stack, Typography } from '@mui/material';
import { fDateTime } from "src/utils/formatTime";
import Iconify from "src/components/iconify/Iconify";
import { s3FileDownloadInNewTab } from "src/utils/fileUtils";

type Props = {
  entityId: string | null;
  entityType: FEntityTypeEnum
}

export default function FFilesList({ entityId, entityType } :Props) {
  const[{ data:filesData }] = useFilesQuery({ variables:{ entityId: entityId, entityType: entityType }, requestPolicy: 'network-only', pause: (entityId === "" || entityId === undefined) });

  return (
    <List disablePadding>
      {filesData?.files.map((file) => {
        const { fileId, name, downloadUrl } = file;

        return (
          <Box key={fileId}>
            <FileItem 
              fileName={name ?? ""}
              fileDownloadUrl={downloadUrl ?? ""}
              authorName=""
              // authorName={authorName} entryTime={entryTime}  
            />
          </Box>
        );
      })}
    </List>
  )
}

type FileItemType = {
  authorName?: string | undefined | null;
  entryTime?: string;
  fileName: string | null;
  fileDownloadUrl: string | null;
}

export function FileItem({ authorName, entryTime, fileName, fileDownloadUrl }: FileItemType) {
  const handleDownloadFile = () => {
    s3FileDownloadInNewTab(fileDownloadUrl);
  }
  
  return (
    <ListItem
      disableGutters
      sx={{
        // alignItems: 'flex-start',
        // py: 3,
      }}
    >
      <Stack width={1}>
        <Stack direction="row" alignItems="center">
          {/* <Box sx={{ flexGrow: 1 }}>
            <Stack direction="column" spacing={1}>
              <Typography variant="subtitle1" > {authorName} </Typography>
              <Typography variant="caption" sx={{ color: 'text.disabled' }}>
                {fDateTime(entryTime)}
              </Typography>   
            </Stack>
          </Box> */}

          <Stack alignItems="flex-start">
            {fileName &&
              <Stack spacing={1} direction="row" sx={{ mt: .5, "cursor":"pointer" }} alignItems="center" onClick={handleDownloadFile} >
                <Typography variant="body1">{fileName}</Typography>        
                <Iconify icon="iconoir:open-in-browser" />
              </Stack>
            }
          </Stack>
        </Stack>
      </Stack>
    </ListItem>    
  )
}