import Iconify from "src/components/iconify/Iconify";
import Label from "src/components/label/Label";

type Props = {
  branchName: string | null | undefined;
  sx?: object;
};

export default function FBranchLabel({ branchName, sx }: Props) {
  return (
    <Label
      endIcon={<Iconify icon="ic:baseline-warehouse" />}  
      sx={{ p:2, color: 'text.primary', fontSize: 16, ...sx }}
      variant="outlined" 
      color={"primary"} 
    >
      {branchName}
    </Label>
  )
}