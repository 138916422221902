import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'src/redux/store';
import { clearErrorMessages } from 'src/@state/redux/fAPIErrorSlice';

// @mui
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
  Stack,
} from '@mui/material';
import Iconify from "src/components/iconify/Iconify";
import Label from "src/components/label/Label";

// ----------------------------------------------------------------------

export default function APIErrorContext() {
  const dispatch = useDispatch();

  const [open, setOpen] = useState<boolean>(false);

  const apiErrorReducer = useSelector((state) => state.apiErrorReducer);

  const handleClose = () => {
    setOpen(false);
    dispatch(clearErrorMessages());
  };

  // this triggers when error messages are added via redux (see App.tsx)
  useEffect(() => {
    if (apiErrorReducer.errorMessages.length > 0) {
      setOpen(true);
    }
  }, [apiErrorReducer.errorMessages]);

  return (
    <>
      <Dialog maxWidth="lg" open={open} onClose={handleClose}>
        <DialogTitle>
          <Stack direction="row" spacing={2} alignItems="center" sx={{ mt: 0.5, color: 'red' }}>
            <Iconify
              width={16}
              icon={'mdi:alert'}
              sx={{ mr: 1 }}
            />
            Error Notification
          </Stack>
        </DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Stack spacing={1}>
              {apiErrorReducer.errorMessages.map((errorMessage, index) => (
                <Label key={index}>{errorMessage}</Label>
              ))}
            </Stack>
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button variant="text" onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
