import numeral, { Numeral } from 'numeral';
import Big from "big.js";

export default class FEPercentage {

  static readonly microsToPercentage = 100;

  static readonly SCALE = 2;

  static readonly ZERO = new FEPercentage(new Big(0));

  static readonly ONE = new FEPercentage(new Big(1));

  static readonly ONE_HUNDRED = new FEPercentage(new Big(100));

  readonly amount: Big;

  readonly amountAsString: string;

  private constructor(big: Big) {

    this.amount = big;
    this.amountAsString = this.amount.toString();
  }

  static fromBig(amount: Big): FEPercentage {
    return new FEPercentage( amount);
  }

  /**
   * Initialize money from floating point format of number.  Ex 0.12 = .12
   * @param amount
   */
  static fromDollarDouble(amount: number): FEPercentage {
    return new FEPercentage(new Big(amount));
  }

  /**
   * @param amount
   */
  static fromDecimal(amount: number): FEPercentage {
    return this.fromBig(new Big(amount).mul(FEPercentage.microsToPercentage));
  }

  static fromString(amount: string): FEPercentage {
    const commaStripped = amount.replace(/,/g, '');
    return FEPercentage.fromDollarDouble(Number(commaStripped));
  }

  add(money: FEPercentage): FEPercentage {
    return FEPercentage.fromBig( this.amount.add(money.amount));
  }

  subtract(money: FEPercentage): FEPercentage {
    return FEPercentage.fromBig(this.amount.minus(money.amount));
  }

  equals(money: FEPercentage): boolean {
    return this.amount.eq(money.amount);
  }

  divide(divisor: number): FEPercentage {
    return FEPercentage.fromBig(this.amount.div(divisor));
  }

  multiply(multiplier: number): FEPercentage {
    return FEPercentage.fromBig(this.amount.mul(multiplier));
  }

  //Returns 0 of equal, negative if less and positive if greater
  compareTo(number :number): number {
    return this.amount.toNumber() - number;
  }

  toDisplayString(includeSign:boolean = false): string
  {
    const format = numeral(this.amount.toNumber()).format('0.000')+ (includeSign ? "%" : "");
    return format;
  }

  //Use this when sending values to the backend
  toDecimal(): number
  {
    return this.amount.div(FEPercentage.microsToPercentage).toNumber();
  }

  toString(): string {
    return this.toDisplayString();
  }
}