import { Suspense, lazy, ElementType } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

// Flitch
export const FPOSCheckoutPage = Loadable(lazy(() => import('../@pages/checkout/FPOSCheckoutPage')));

// ERP
export const FWorkflowEntityHeaderPage = Loadable(lazy(() => import('../@sections/entity/FWorkflowEntityHeaderPage')));

export const FDashboardHomePage = Loadable(lazy(() => import('../@pages/dashboard/FDashboardHomePage')));

export const OrganizationProfilePage = Loadable(lazy(() => import('../pages/erp/org/OrganizationProfilePage')));
export const FPricingLevelCreateEditPage = Loadable(lazy(() => import('../@sections/pricing/FPricingLevelCreateEditPage')));

export const FUserProfilePage = Loadable(lazy(() => import('../@pages/user/FUserProfilePage')));

export const FSalesOrderListPage = Loadable(lazy(() => import('../@pages/so/FSalesOrderListPage')));
export const FSalesOrderDeliveryPage = Loadable(lazy(() => import('../@pages/so/FSalesOrderDeliveryPage')));
export const FSalesOrderCreateEditPage = Loadable(lazy(() => import('../@pages/so/FSalesOrderCreateEditPage')));
export const FSalesOrderViewDetailsPage = Loadable(lazy(() => import('../@pages/so/FSalesOrderViewDetailsPage')));
export const FSalesOrderPDFPage = Loadable(lazy(() => import('../@pages/so/FSalesOrderPDFPage')));

export const FCustomerListPage = Loadable(lazy(() => import('../@pages/customer/FCustomerListPage')));
export const FCustomerCreateEditPage = Loadable(lazy(() => import('../@pages/customer/FCustomerCreateEditPage')));
export const FCustomerProfilePage = Loadable(lazy(() => import('../@pages/customer/FCustomerProfilePage')));

export const FJobListPage = Loadable(lazy(() => import('../@pages/job/FJobListPage')));
export const FJobCreateEditPage = Loadable(lazy(() => import('../@pages/job/FJobCreateEditPage')));

export const FInventoryListPage = Loadable(lazy(() => import('../@pages/inventory/FInventoryListPage')));
export const FInventoryCreateEditPage = Loadable(lazy(() => import('../@pages/inventory/FInventoryCreateEditPage')));

export const FPurchaseOrderListPage = Loadable(lazy(() => import('../@pages/po/FPurchaseOrderListPage')));
export const FPurchaseOrderCreateEditPage = Loadable(lazy(() => import('../@pages/po/FPurchaseOrderCreateEditPage')));
export const FPurchaseOrderCreateFromSmartOrderPage = Loadable(lazy(() => import('../@pages/po/FPurchaseOrderCreateFromSmartOrderPage')));
export const FPurchaseOrderPDFPage = Loadable(lazy(() => import('../@pages/po/FPurchaseOrderPDFPage')));
export const FPurchaseOrderViewDetailsPage = Loadable(lazy(() => import('../@pages/po/FPurchaseOrderViewDetailsPage')));
export const FPurchaseOrderSmartOrderPage = Loadable(lazy(() => import('../@pages/po/FPurchaseOrderSmartOrderPage')));
export const FPurchaseOrderCreateFromSpecialOrderPage = Loadable(lazy(() => import('../@pages/po/FPurchaseOrderCreateFromSpecialOrderPage')));

export const FInventoryReceiptListPage = Loadable(lazy(() => import('../@pages/receipt/FInventoryReceiptListPage')));
export const FInventoryReceiptCreatePage = Loadable(lazy(() => import('../@pages/receipt/FInventoryReceiptCreatePage')));
export const FInventoryReceiptEditPage = Loadable(lazy(() => import('../@pages/receipt/FInventoryReceiptEditPage')));
export const FInventoryReceiptFromPurchaseOrderPage = Loadable(lazy(() => import('../@pages/receipt/FInventoryReceiptFromPurchaseOrderPage')));

// Invoice
export const FInvoiceListPage = Loadable(lazy(() => import('../@pages/invoice/FInvoiceListPage')));
export const FInvoicePrintCheckPage = Loadable(lazy(() => import('../@pages/invoice/FInvoiceCheckPage')));

// Payable
export const FInvoiceFromPurchaseOrderPage = Loadable(lazy(() => import('../@pages/invoice/FInvoiceFromPurchaseOrderPage')));
export const FInvoicePayableCreateEditPage = Loadable(lazy(() => import('../@pages/invoice/FInvoicePayableCreateEditPage')));
export const FInvoicePayableViewDetailsPage = Loadable(lazy(() => import('../@pages/invoice/FInvoicePayableViewDetailsPage')));
export const FInvoicePDFPage = Loadable(lazy(() => import('../@pages/invoice/FInvoicePDFPage')));

// Receivable
export const FInvoiceFromSalesOrderPage = Loadable(lazy(() => import('../@pages/invoice/FInvoiceFromSalesOrderPage')));
export const FInvoiceFromDeliveryPage = Loadable(lazy(() => import('../@pages/invoice/FInvoiceFromDeliveryPage')));
export const FInvoiceReceivableCreateEditPage = Loadable(lazy(() => import('../@pages/invoice/FInvoiceReceivableCreateEditPage')));
export const FInvoiceReceivableViewDetailsPage = Loadable(lazy(() => import('../@pages/invoice/FInvoiceReceivableViewDetailsPage')));

export const FVendorListPage = Loadable(lazy(() => import('../@pages/vendor/FVendorListPage')));
export const FVendorCreateEditPage = Loadable(lazy(() => import('../@pages/vendor/FVendorCreateEditPage')));
export const FDoItBestPriceUpdateListPage = Loadable(lazy(() => import('../@pages/vendor/dib/FDoItBestPriceUpdateListPage')));
export const FVendorProfilePage = Loadable(lazy(() => import('../@pages/vendor/FVendorProfilePage')));

export const FDeliveryListPage = Loadable(lazy(() => import('../@pages/delivery/FDeliveryListPage')));

export const FOrderReturnsListPage = Loadable(lazy(() => import('../@pages/returns/FOrderReturnsListPage')));
export const FOrderReturnsViewDetailsPage = Loadable(lazy(() => import('../@pages/returns/FOrderReturnsViewDetailsPage')));
export const FOrderReturnsPDFPage = Loadable(lazy(() => import('../@pages/returns/FOrderReturnsPDFPage')));

export const FCashDrawerListPage = Loadable(lazy(() => import('../@pages/cashdrawer/FCashDrawerListPage')));
export const FCashDrawerSessionCloseoutPage = Loadable(lazy(() => import('../@pages/cashdrawer/FCashDrawerSessionCloseoutPage')));
export const FCashDrawerSessionListPage = Loadable(lazy(() => import('../@pages/cashdrawer/FCashDrawerSessionListPage')));
export const FCashDrawerSessionViewPage = Loadable(lazy(() => import('../@pages/cashdrawer/FCashDrawerSessionViewPage')));




// Accounting
export const AccountingConfigurationPage = Loadable(lazy(() => import('../sections/@erp/org/accounting/AccountingConfigurationPage')));

// AUTH
export const LoginPage = Loadable(lazy(() => import('../pages/auth/LoginPage')));
export const RegisterPage = Loadable(lazy(() => import('../pages/auth/RegisterPage')));
export const VerifyCodePage = Loadable(lazy(() => import('../pages/auth/VerifyCodePage')));
export const NewPasswordPage = Loadable(lazy(() => import('../pages/auth/NewPasswordPage')));
export const ResetPasswordPage = Loadable(lazy(() => import('../pages/auth/ResetPasswordPage')));

// MAIN
export const Page500 = Loadable(lazy(() => import('../pages/Page500')));
export const Page403 = Loadable(lazy(() => import('../pages/Page403')));
export const Page404 = Loadable(lazy(() => import('../pages/Page404')));
export const HomePage = Loadable(lazy(() => import('../pages/HomePage')));
export const FaqsPage = Loadable(lazy(() => import('../pages/FaqsPage')));
export const AboutPage = Loadable(lazy(() => import('../pages/AboutPage')));
export const Contact = Loadable(lazy(() => import('../pages/ContactPage')));
export const PricingPage = Loadable(lazy(() => import('../pages/PricingPage')));
export const PaymentPage = Loadable(lazy(() => import('../pages/PaymentPage')));
export const ComingSoonPage = Loadable(lazy(() => import('../pages/ComingSoonPage')));
export const MaintenancePage = Loadable(lazy(() => import('../pages/MaintenancePage')));


