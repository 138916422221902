// @mui
import {
  MenuItem,
} from '@mui/material';

import { RHFSelect } from "src/components/hook-form";
import { FORM_FIELD_WIDTH } from 'src/@components/@utils/constants';
import { FListProps } from "./types";

export default function FDropDownList({ isSmall = true, isDisabled = false, width = FORM_FIELD_WIDTH, selector, onSelected, onClick, defaultValue, keyToHighlight = '-' } : FListProps) {
  const handleOnChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    onSelected && onSelected(event.target.value as string);
  }

  const handleOnClick = (id: any, event: any ) => {
    onClick && onClick(id);
  }


  return (
    <RHFSelect 
      size={isSmall ? "small" : "medium"}
      disabled={isDisabled} 
      name={selector.id}
      defaultValue={defaultValue}
      label={selector.label}
      placeholder={selector.placeHolder}
      onChange={handleOnChange}// overrides default RHF behavior
      SelectProps={{ 
        native: false // must be set to false for MenuItem children
        ,MenuProps: {
          PaperProps: {
            sx: { maxHeight: 500, border: '.5px solid', mt: .5 },
          },
        },
      
      }}
      sx={{
        width: { md: width }
      }}
    >
      {selector.options.map((option) => (
        option.level ? 
        (
          option?.level === 1 ?
            <MenuItem 
              key={option.key} 
              value={option.key}
              sx={option.style}
              onClick={(event) => handleOnClick(option.key, event)}
            >{option.label}</MenuItem>
          :
            // Level 2
            <MenuItem 
              key={option.key} 
              value={option.key}
              sx={{ pl: 5, ...option.style}}
            >{option.label}</MenuItem>
        ) 
        : 
        (
          <MenuItem 
            key={option.key} 
            value={option.key}
            sx={{ fontWeight: option.key === keyToHighlight ? 'bold' : '0', ...option.style  }}
            onClick={(event) => handleOnClick(option.key, event)}
          >{option.label}</MenuItem>
        )
      ))}
    </RHFSelect>
  )
}