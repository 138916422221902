// routes
import { PATH_AUTH } from '../routes/paths';
// utils
import axios from '../utils/axios';
import axiosGraphQL from '../utils/axiosGraphQL';

// ----------------------------------------------------------------------

function jwtDecode(token: string) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );

  return JSON.parse(jsonPayload);
}

// ----------------------------------------------------------------------

export const isValidToken = (accessToken: string) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);

  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

// ----------------------------------------------------------------------

export const tokenExpired = (exp: number) => {
  // eslint-disable-next-line prefer-const
  let expiredTimer;

  const currentTime = Date.now();

  // Test token expires after 10s
  // const timeLeft = currentTime + 10000 - currentTime; // ~10s
  const timeLeft = exp * 1000 - currentTime;

  const nowAsDate = new Date();
  const timeLeftAsDate = new Date(exp*1000);
  const timeLeftAsSeconds = timeLeftAsDate.getTime() - nowAsDate.getTime();

  const expirationTime = Date.now() + timeLeft;
  console.log("Your session will expire at [" +new Date(expirationTime)+ "]");

  // clearTimeout(expiredTimer);

  // expiredTimer = setTimeout(() => {
  //   alert('You session has expired. Please login again.');

  //   localStorage.removeItem('accessToken');

  //   // redirects user to the login page
  //   // window.location.href = PATH_AUTH.login;
  //   logout();
  // }, timeLeftAsSeconds);
};

// ----------------------------------------------------------------------

export const setSession = (accessToken: string | null) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);

    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    // This function below will handle when token is expired
    const { exp } = jwtDecode(accessToken); // ~3 days by minimals server
    tokenExpired(exp);
  } else {
    localStorage.removeItem('accessToken');

    delete axios.defaults.headers.common.Authorization;
  }
};

export const setAxiosGraphQLSession = (accessToken: string | null) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);

    axiosGraphQL.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    // This function below will handle when token is expired
    const { exp } = jwtDecode(accessToken); // ~3 days by minimals server
    tokenExpired(exp);
  } else {
    localStorage.removeItem('accessToken');

    delete axiosGraphQL.defaults.headers.common.Authorization;
  }
};
