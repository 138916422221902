import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
import { createSlice } from '@reduxjs/toolkit';
// utils
import { IInventoryReceiptState, ReceivingPOLineItemFormValueProps } from '../../@types/receiving';
import { stat } from 'fs';

// ----------------------------------------------------------------------

const initialState: IInventoryReceiptState = {
  isLoading: false,
  error: null,
  rInventoryReceipt: {
    rLineItems: [],
    rSubtotal: 0,
    rTotal: 0,
    rTotalItems: 0,
    rInventoryReceiptInput: null,
  }
};

const slice = createSlice({
  name: 'inventoryReceiptReduxKey',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getInventoryReceiptDetails(state, action) {
      const lineItems: ReceivingPOLineItemFormValueProps[] = action.payload;

      const totalItems = sum(lineItems.map((lineItem) => lineItem.fReceivedQty));
      const subtotal = sum(lineItems.map((lineItem) => lineItem.fReceivedUnitSellPrice * lineItem.fReceivedQty));

      state.rInventoryReceipt.rLineItems = lineItems;
      state.rInventoryReceipt.rSubtotal = subtotal;

      state.rInventoryReceipt.rTotal = subtotal;
      state.rInventoryReceipt.rTotalItems = totalItems;
    },

    addToInventoryReceipt(state, action) {
      const newLineItem:ReceivingPOLineItemFormValueProps = action.payload;

      // a SalesOrder can have the same SKU on multiple line, so do not update Qty on existing SKUs
      state.rInventoryReceipt.rLineItems = [...state.rInventoryReceipt.rLineItems, newLineItem];

      state.rInventoryReceipt.rLineItems = uniqBy([...state.rInventoryReceipt.rLineItems, newLineItem], 'fProductId');
    },

    deleteLineItem(state, action) {
      const updateInventoryReceipt = state.rInventoryReceipt.rLineItems.filter((lineItem) => lineItem.fProductId !== action.payload);
      state.rInventoryReceipt.rLineItems = updateInventoryReceipt;
    },

    resetInventoryReceipt(state) {
      state.rInventoryReceipt.rLineItems = [];
      state.rInventoryReceipt.rTotal = 0;
      state.rInventoryReceipt.rSubtotal = 0;
    },

    updateQuantity(state, action) {
      const { fProductId, fReceivedQty, fReceivedUnitSellPrice } : ReceivingPOLineItemFormValueProps = action.payload;
      const updateProducts = state.rInventoryReceipt.rLineItems.map((lineItem) => {
        if (lineItem.fProductId === fProductId) {
          return {
            ...lineItem,
            fReceivedQty: fReceivedQty,
            fReceivedUnitSellPrice: fReceivedUnitSellPrice
          };
        }
        return lineItem;
      });

      state.rInventoryReceipt.rLineItems = updateProducts;
    },

    updateItemPrice(state, action) {
      const { fProductId, fReceivedQty, fReceivedUnitSellPrice } : ReceivingPOLineItemFormValueProps = action.payload;

      const updateProducts = state.rInventoryReceipt.rLineItems.map((lineItem) => {
        if (lineItem.fProductId === fProductId) {
          return {
            ...lineItem,
            fReceivedQty: fReceivedQty,
            fReceivedUnitSellPrice: fReceivedUnitSellPrice * 100,
          };
        }
        return lineItem;
      });

      state.rInventoryReceipt.rLineItems = updateProducts;
    },

    updateFreightCost(state, action) {

    },

    setInventoryReceiptInput(state, action) {
      state.rInventoryReceipt.rInventoryReceiptInput = action.payload;
    }

  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  getInventoryReceiptDetails,
  addToInventoryReceipt,
  resetInventoryReceipt,
  deleteLineItem,
  updateQuantity,
  updateItemPrice,
  setInventoryReceiptInput,
} = slice.actions;
// ----------------------------------------------------------------------



