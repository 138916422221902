import { FAccount } from "src/generated/graphql";
import { DeepPartial } from "./fDeepPartial";
import { FRHFAccount, FRHFBranch, FRHFOrganization, accountBEtoFE, branchBEtoFE, organizationBEtoFE } from "./fOrganization";
import { FRHFCashDrawer, cashDrawerBEtoFE } from "./fCashDrawer";
import { CognitoUser } from "amazon-cognito-identity-js";
import { UserAttributeType } from "src/auth/AwsCognitoContext";

export interface FRHFAppUser {
  rhfCognitoUser:CognitoUser;
  rhfCognoitoUserAttributes:UserAttributeType;
  rhfOrganization: FRHFOrganization;
  rhfAccount: FRHFAccount;
  rhfBranch: FRHFBranch;
  rhfCashDrawer: FRHFCashDrawer;
}

export function appUserBEtoFE(cognitorUser:CognitoUser, userAttributes:UserAttributeType, account:DeepPartial<FAccount | null | undefined>): FRHFAppUser {
  return {
    rhfCognitoUser: cognitorUser,
    rhfCognoitoUserAttributes: userAttributes,
    rhfOrganization: organizationBEtoFE(account?.organization),
    rhfAccount: accountBEtoFE(account),
    rhfBranch: branchBEtoFE(account?.defaultBranch),
    rhfCashDrawer: cashDrawerBEtoFE(account?.cashDrawer)
  }
}