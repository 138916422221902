import * as React from 'react';
import {Fragment} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {Grid, TableCell} from "@mui/material";
import FRHFTextField from "../../@components/@input/FRHFTextField";
import {FRHFAddress} from "../../@state/form/fOrganization";
import {useFormContext} from "react-hook-form";
import {CREATE_NEW_ADDRESS_ID} from "./FDeliveryAddressSelectorSimple";


type Props = {
  setOpen: (open: boolean) => void;
  open: boolean;
  addressCreated?: (address: FRHFAddress) => void;
  cancelClicked?: () => void;
}

export default function FAddressCreateModal({setOpen, open, addressCreated, cancelClicked}: Props) {

  const {getValues, setValue} = useFormContext();

  const handleClose = () => {
    setOpen(false);
    cancelClicked && cancelClicked();
  };

  const handleCreate = () => {
    addressCreated && addressCreated(
      {
        rhfOrganizationId: "0",
        rhfPartnerId: null,
        rhfJobId: null,
        rhfAddressId: CREATE_NEW_ADDRESS_ID,
        rhfCountryCode: "US",
        rhfPrimary: false,
        rhfStreetLine1: getValues("rhfStreetLine1"),
        rhfStreetLine2: getValues("rhfStreetLine2"),
        rhfCity: getValues("rhfCity"),
        rhfState: getValues("rhfState"),
        rhfPostalCode: getValues("rhfPostalCode")
      });
    setOpen(false);
  };


  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>Add Address</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{paddingTop: "5px"}}>
            <Grid item xs={12}>
              <FRHFTextField
                size="small"
                rhfName={`rhfStreetLine1`}
                label="Street Line 1"
                placeholder=""
                InputLabelProps={{shrink: true}}
              />
            </Grid>

            <Grid item xs={12}>
              <FRHFTextField
                size="small"
                rhfName={`rhfStreetLine2`}
                label="Street Line 2"
                placeholder=""
                InputLabelProps={{shrink: true}}
              />
            </Grid>

            <Grid item xs={6}>
              <FRHFTextField
                size="small"
                rhfName={`rhfCity`}
                label="City"
                placeholder=""
                InputLabelProps={{shrink: true}}
              />
            </Grid>

            <Grid item xs={2}>
              <FRHFTextField
                size="small"
                rhfName={`rhfState`}
                label="State"
                placeholder=""
                InputLabelProps={{shrink: true}}
              />

            </Grid>
            <Grid item xs={4}>
              <FRHFTextField
                size="small"
                rhfName={`rhfPostalCode`}
                label="Postal Code"
                placeholder=""
                InputLabelProps={{shrink: true}}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" onClick={handleCreate}>Create</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}