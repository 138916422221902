import React, { createContext, useContext, useState, useEffect } from 'react';


export type EnvType = {
  // **** IMPORTANT.  Do not place anything secret in frontend config ****
  REACT_APP_AWS_COGNITO_CLIENT_ID: string,
  REACT_APP_AWS_COGNITO_USER_POOL_ID: string,
};

export const EnvContext = createContext<EnvType | null>(null);


type EnvProviderProps = {
  children: React.ReactNode;
};
export const EnvProvider = ({ children } : EnvProviderProps) => {
  const [env, setEnv] = useState<EnvType| null>(null);

  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchEnv = async () => {

      try {
        const response = await fetch('/api/pub/env');
        const data = await response.json();

        setEnv(data);
      } catch (error) {
        console.error(error);
        setError('Env fetch error');
      }
    };

    fetchEnv();
  }, []);

  if (!env) {
    return <EnvContext.Provider value = {null} >
      { error && <div>Error loading env: {error}</div>}
    </EnvContext.Provider>;
  }
  else
    return (
      <EnvContext.Provider value={{...env}}>
        { env &&
         children
        }
        { !env &&
          <div>Loading Env...</div>
        }
      </EnvContext.Provider>
    );
};