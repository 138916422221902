import {FBillingLevel, FChargeAccount, FChargeAccountInput, FPostingType} from "src/generated/graphql";
import { DeepPartial } from "./fDeepPartial";
import { FRHFPartner } from "./fOrganization";
import { FRHFChargeAccount } from "./fChargeAccount";
import FEMoney from "src/@model/FEMoney";
import FEPercentage from "src/@model/FEPercentage";

export function chargeAccountBEtoFE(ca:DeepPartial<FChargeAccount|null|undefined>): FRHFChargeAccount {
  return {
    rhfChargeAccountId: ca?.chargeAccountId ?? "",
    rhfPartnerId: ca?.partnerId ?? "",
    rhfEnabled: ca?.enabled ?? false,
    rhfCreditLimit: ca?.creditLimit ? FEMoney.fromMicros(ca.creditLimit) : FEMoney.ZERO,
    rhfFinanceChargeRate: ca?.financeChargeRate ? FEPercentage.fromDecimal(ca.financeChargeRate) : FEPercentage.ZERO,
    rhfStatementDeliveryMail: ca?.deliverByMail ?? false,
    rhfStatementDeliveryEMail: ca?.deliverByEmail ?? true,
    rhfPostingType: ca?.postingType ?? FPostingType.BalanceForward,
    rhfBillingLevel: ca?.billingLevel ?? FBillingLevel.AccountLevel,

    // TODO
    rhfDueDate: 30,
  }
}

export function chargeAccountFEtoBE(customer:FRHFPartner):FChargeAccountInput|null {
  return {
    enabled: customer.rhfChargeAccount?.rhfEnabled ?? true,
    chargeAccountId: customer.rhfChargeAccount?.rhfChargeAccountId ?? "",
    partnerId: customer.rhfPartnerId,
    creditLimit: customer.rhfChargeAccount?.rhfCreditLimit?.toMicros() ?? 0,
    deliverByMail: customer.rhfChargeAccount?.rhfStatementDeliveryMail ?? false,
    deliverByEmail: customer.rhfChargeAccount?.rhfStatementDeliveryEMail ?? true,
    financeChargeRate: customer.rhfChargeAccount?.rhfFinanceChargeRate?.toDecimal() ?? 0,
    postingType: customer.rhfChargeAccount?.rhfPostingType ?? FPostingType.BalanceForward,
    billingLevel: customer.rhfChargeAccount?.rhfBillingLevel ?? FBillingLevel.AccountLevel
  }
}

// export function statementBEtoFE(stmt:DeepPartial<FStatement|null|undefined>): FRHFStatement {
//   return {
//     rhfStatementId: stmt?.statementId ?? "",
//     rhfChargeAccountId: stmt?.chargeAccountId ?? "",
//     rhfStatementFileId: stmt?.statementFileId ?? "",
//     rhfStatementDate: stmt?.statementDate ?? "",
//     rhfBeginningBalance: new FEMoney(stmt?.beginningBalance ?? 0),
//     rhfCharge: new FEMoney(stmt?.charge ?? 0),
//     rhfCredit: new FEMoney(stmt?.credit ?? 0),
//     rhfPayment: new FEMoney(stmt?.payment ?? 0),
//     rhfFinanceCharge: new FEMoney(stmt?.financeCharge ?? 0),
//     rhfBalance30: new FEMoney(stmt?.balance30 ?? 0),
//     rhfBalance60: new FEMoney(stmt?.balance60 ?? 0),
//     rhfBalance90: new FEMoney(stmt?.balance90 ?? 0),
//     // TODO
//     rhfBalance90Plus: new FEMoney(0),
//     rhfRemainingBalance: new FEMoney(stmt?.remainingBalance ?? 0),
//   }
// }