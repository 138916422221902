// @mui
import { TextField, TextFieldProps } from '@mui/material';

import { useFormContext } from 'react-hook-form';

import FBaseTextField from './FBaseTextField';

// ----------------------------------------------------------------------

type Props = TextFieldProps & {
  rhfName: string;
  label?: string;
  placeholder?: string;
  onValueChange?: (value: string) => void;
  isSizeSmall?: boolean;
  width?: number;
};

export default function FRHFTextField({ 
  rhfName, 
  label, 
  placeholder, 
  onValueChange, 
  isSizeSmall = true, 
  width = 1,
  ...other 
}: Props) {
  const { watch, setValue } = useFormContext();
  const initialValue:string = watch(rhfName);

  const handleTextUpdate = (newValue: string) => {
    setValue(rhfName, newValue);
    if (onValueChange) onValueChange(newValue);
  }

  const preventNumericScroll = (event:any) => {
    event.target.blur()
  }

  return (
    <TextField
      autoComplete='off'
      size={ isSizeSmall ? "small" : "medium" }
      sx={{ width: width }}
      label={label}
      placeholder={placeholder}
      value={initialValue}
      onChange={(event) => handleTextUpdate(event.target.value)}
      onWheel={(event) => preventNumericScroll(event)}
      InputLabelProps={{ shrink: true }}
      {...other}
    />
  );
}

