import axios from 'axios';
// config
// import { GRAPHQL_HOST_API_KEY } from '../config';

// ----------------------------------------------------------------------
// may be needed if we deploy web and BE as separate servers
// const axiosGraphQLInstance = axios.create({ baseURL: GRAPHQL_HOST_API_KEY });
const axiosGraphQLInstance = axios.create();

axiosGraphQLInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'GraphQL - Something went wrong')
);

export default axiosGraphQLInstance;
