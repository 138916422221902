import { Box, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
// rhf
import { useFormContext } from 'react-hook-form';
// error
import { FDropDownList, FSelector, FSelectorOption } from 'src/@components/list';
import { FRHFPOSCustomer, FRHFJob, FRHFAddress, addressBEtoFE } from "src/@state/form/fOrganization";
import FAddressLabel from "./FAddressLabel";
import FAddressCreateModal from "./FAddressCreateModal";
import * as React from "react";

type Props  = {
  isReadOnly?: boolean;
  onAddressSelected?: (address:FRHFAddress) => void;
  disabled?: boolean;
  includeLabel?: boolean;
}
export const CREATE_NEW_ADDRESS_ID = "-1";

export default function FDeliveryAddressSelectorSimple({ isReadOnly = false, disabled = false, includeLabel = true, onAddressSelected }: Props) {
  const { setValue, getValues, watch } = useFormContext();
  const [openCreate, setOpenCreate] = React.useState(false);

  // when customer is added, using 'watch' will capture changes
  const rhfCustomer:FRHFPOSCustomer = watch("rhfCustomer");
  const rhfJob:FRHFJob = watch("rhfJob");
  const rhfDeliveryAddress:FRHFAddress | null = watch("rhfSalesOrder.rhfDeliveryAddress");

  const [selector, setSelector] = useState<FSelector>();

  const handleAddressCreated = (address:FRHFAddress) => {
    setValue("rhfSalesOrder.rhfDeliveryAddress", address);
    setOpenCreate(false);
  }
  const handleAddressCancelClicked = () => {
    setValue("rhfSalesOrder.rhfDeliveryAddress", null);
    setValue("rhfSalesOrder.rhfDeliveryAddress.rhfAddressId", null); //Deselect
  }

  useEffect(() => {
    // if there are any updates to the customer and/or job, reset the delivery address...
    if (rhfDeliveryAddress != null && ((rhfCustomer && rhfCustomer.rhfPartnerId !== "" && rhfCustomer.rhfPartnerId !== rhfDeliveryAddress.rhfPartnerId) ||
        (rhfJob && rhfDeliveryAddress.rhfJobId != null && rhfJob.rhfJobId !== rhfDeliveryAddress.rhfJobId))
      && rhfDeliveryAddress.rhfAddressId !== CREATE_NEW_ADDRESS_ID) {
      setValue("rhfSalesOrder.rhfDeliveryAddress", null);
    }
    updateOptions(true);
  }, [rhfCustomer, rhfJob]);

  useEffect(() => {
    updateOptions(false);
  }, [rhfDeliveryAddress]);


  const updateOptions = (reset : boolean) => {
    const options: FSelectorOption[] = [];
    if (  rhfDeliveryAddress?.rhfAddressId !== CREATE_NEW_ADDRESS_ID ) {
      options.push({
        key: CREATE_NEW_ADDRESS_ID,
        label: "Create New",
        style: { fontWeight: 'bold' }
      })
    }
    else
    {
      options.push({
        key: CREATE_NEW_ADDRESS_ID,
        label: rhfDeliveryAddress.rhfStreetLine1,
      })
    }
    let foundSOAddress : boolean = false;
    // show Job addresses...
    if (rhfJob && rhfJob.rhfJobId !== '-') {
      rhfCustomer?.jobOptions?.map((job:FRHFJob) => {
        if (rhfJob.rhfJobId === job.rhfJobId) {
          if (job.rhfAddresses) {
            job.rhfAddresses?.map((address:FRHFAddress) => {
              if ( address.rhfAddressId === rhfDeliveryAddress?.rhfAddressId ) {
                foundSOAddress = true
              }
              address.rhfJobId = job.rhfJobId;
              address.rhfPartnerId = rhfCustomer.rhfPartnerId;
              options.push({
                key: address.rhfAddressId,
                label: address.rhfStreetLine1+ " " + address.rhfStreetLine2+ " " + address.rhfCity+ ", " + address.rhfState+ " " + address.rhfPostalCode,
              })

              // ...while Delivery Address options are being hydrated, select a default address
              if (address.rhfPrimary && reset &&
                (rhfDeliveryAddress && rhfDeliveryAddress.rhfJobId != null && rhfDeliveryAddress.rhfJobId !== job.rhfJobId)
                && rhfDeliveryAddress.rhfAddressId !== CREATE_NEW_ADDRESS_ID) {
                setValue('rhfSalesOrder.rhfDeliveryAddress', address);
              }
            })
          }
        }
      });


    } else {
      // OR show Customer addresses
      if (rhfCustomer?.addressOptions) {
        rhfCustomer?.addressOptions?.map((address:FRHFAddress) => {
          if ( address.rhfAddressId === rhfDeliveryAddress?.rhfAddressId ) {
            foundSOAddress = true
          }
          address.rhfPartnerId = rhfCustomer.rhfPartnerId;
          options.push({
            key: address.rhfAddressId,
            label: address.rhfStreetLine1+ " " + address.rhfStreetLine2+ " " + address.rhfCity+ ", " + address.rhfState+ " " + address.rhfPostalCode,
          })

          // ...while Delivery Address options are being hydrated, select a default address
          if (address.rhfPrimary && reset && (rhfDeliveryAddress && rhfDeliveryAddress.rhfPartnerId !== rhfCustomer.rhfPartnerId) && rhfDeliveryAddress.rhfAddressId !== CREATE_NEW_ADDRESS_ID) {
            setValue('rhfSalesOrder.rhfDeliveryAddress', address);
          }
        })
      }
    }

    if ( !foundSOAddress ) {
      // if the Sales Order address is not in the list of addresses, add it
      if ( rhfDeliveryAddress?.rhfAddressId && rhfDeliveryAddress.rhfAddressId !== CREATE_NEW_ADDRESS_ID) {
        options.push({
          key: rhfDeliveryAddress.rhfAddressId,
          label: rhfDeliveryAddress.rhfStreetLine1+ " " + rhfDeliveryAddress.rhfStreetLine2+ " "
            + rhfDeliveryAddress.rhfCity+ ", " + rhfDeliveryAddress.rhfState+ " " + rhfDeliveryAddress.rhfPostalCode, })
      }
    }
   
    setSelector({ 
      id: "rhfSalesOrder.rhfDeliveryAddress.rhfAddressId",
      label: "",
      placeHolder: "",
      options: options
    })  

  }

  const handleDropDownSelected = (selected: string) => {
    if ( selected === CREATE_NEW_ADDRESS_ID ) {
      setOpenCreate(true);
    }
    else {
      setValue("rhfSalesOrder.rhfDeliveryAddress.rhfAddressId", selected);
    }
  }

  const handleDropDownClicked = (clickedId: string) => {
    if ( clickedId === CREATE_NEW_ADDRESS_ID ) {
      setOpenCreate(true);
    }
  }

  return (
    <Stack>
      {isReadOnly && <FAddressLabel rhfAddress={rhfDeliveryAddress} />}

      <Stack direction="row" justifyContent="space-between" alignItems="center" alignContent="center">
        {includeLabel && 
          <Typography variant="subtitle1" sx={{ color: 'text.secondary' }}>
          Delivery Address:
          </Typography>
        }

        <Stack>
          {!isReadOnly && selector &&
            <FDropDownList 
              isDisabled={disabled}
              selector={selector}
              onSelected={handleDropDownSelected}
              onClick={handleDropDownClicked}
            />
          }      
        </Stack>    
      </Stack>
      <FAddressCreateModal open={openCreate} setOpen={setOpenCreate} addressCreated={handleAddressCreated} cancelClicked={handleAddressCancelClicked}></FAddressCreateModal>
    </Stack>
  )
}