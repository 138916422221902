import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

type IErrorState = {
  errorMessages: string[];
};

const initialState: IErrorState = {
  errorMessages: [],
};

const slice = createSlice({
  name: 'apiErrorSliceReduxKey',
  initialState,
  reducers: {
    addErrorMessages(state, action) {
      state.errorMessages = action.payload;
    },

    clearErrorMessages(state) {
      state.errorMessages = [];
    }
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  addErrorMessages,
  clearErrorMessages
} = slice.actions;
