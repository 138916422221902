import { Box, Typography } from '@mui/material';
import { ADDRESS_FIELD_WIDTH } from 'src/@components/@utils/constants';
import { FRHFAddress } from 'src/@state/form/fOrganization';

type PartnerProps = {
  selected?: boolean;
  rhfAddress: FRHFAddress | null;
};

export default function FAddressLabel({ selected = false, rhfAddress }: PartnerProps) {
  
  return (
    <>
      <Box sx={{ width:1, cursor: 'pointer', p: .25 }}>
        {rhfAddress && 
          <Box sx={{ p: selected ? 1 : 0, border: selected ? 1 : 0, borderRadius: selected ? 1 : 0, width: ADDRESS_FIELD_WIDTH }}>
            <Typography variant="body2" sx={{ mt: 1 }}>
              {rhfAddress.rhfStreetLine1}
            </Typography>
            {rhfAddress.rhfStreetLine2 &&
              <Typography variant="body2" sx={{ mt: .5 }}>
                {rhfAddress.rhfStreetLine2}
              </Typography>          
            }
            <Typography variant="body2" sx={{ mb: 0.5 }}>
              {rhfAddress.rhfCity} {rhfAddress.rhfState}, {rhfAddress.rhfPostalCode}
            </Typography>        
          </Box>
        }
      </Box>
    </>
  );
}