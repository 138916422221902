import { useContext } from 'react';
import { EnvContext } from './EnvProvider';
// ----------------------------------------------------------------------

export const useEnvContext = () => {
  const context = useContext(EnvContext);

  if (!context) throw new Error('useEnvContext context must be use inside EnvProvider');

  return context;
};
