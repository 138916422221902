import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
import { createSlice } from '@reduxjs/toolkit';
// utils
import { ISalesOrderState, SalesOrderLineItemFormValueProps } from '../../@types/salesOrder';
import axiosGraphQL from 'src/utils/axiosGraphQL';
import { ITableCriteria } from 'src/@types/table';
//
import { dispatch } from 'src/redux/store';
// ----------------------------------------------------------------------

const initialState: ISalesOrderState = {
  isLoading: false,
  error: null,
  rQuote: {
    rLineItems: [],
    rSubtotal: 0,
    rTotal: 0,
    rDiscount: 0,
    rTaxes: 0,
    rTotalItems: 0,
  },
  salesOrderSearchResults: [],
};

const slice = createSlice({
  name: 'salesOrderReduxKey',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getQuoteDetails(state, action) {
      const lineItems: SalesOrderLineItemFormValueProps[] = action.payload;

      const totalItems = sum(lineItems.map((lineItem) => lineItem.fQty));
      const subtotal = sum(lineItems.map((lineItem) => lineItem.fUnitSellPrice * lineItem.fQty));

      state.rQuote.rLineItems = lineItems;
      state.rQuote.rDiscount = state.rQuote.rDiscount || 0;
      state.rQuote.rSubtotal = subtotal;

      // need to get this from organization
      state.rQuote.rTaxes = 0;
      state.rQuote.rTotal = subtotal - state.rQuote.rDiscount + state.rQuote.rTaxes;
      state.rQuote.rTotalItems = totalItems;
    },

    addToQuote(state, action) {
      const newLineItem:SalesOrderLineItemFormValueProps = action.payload;

      // a SalesOrder can have the same SKU on multiple line, so do not update Qty on existing SKUs
      state.rQuote.rLineItems = [...state.rQuote.rLineItems, newLineItem];

      state.rQuote.rLineItems = uniqBy([...state.rQuote.rLineItems, newLineItem], 'fItemPosition');
    },

    deleteLineItem(state, action) {
      console.log("deleteLineItem " + action.payload);
      const updatedQuote = state.rQuote.rLineItems.filter((lineItem) => lineItem.fItemPosition !== action.payload);
      state.rQuote.rLineItems = updatedQuote;
      console.log(updatedQuote)
    },

    resetQuote(state) {
      state.rQuote.rLineItems = [];
      state.rQuote.rTotal = 0;
      state.rQuote.rSubtotal = 0;
      state.rQuote.rDiscount = 0;
      state.rQuote.rTaxes = 0;
    },

    updateQuantity(state, action) {
      const { fItemPosition, fQty, fUnitSellPrice } : SalesOrderLineItemFormValueProps = action.payload;
      const updateProducts = state.rQuote.rLineItems.map((lineItem) => {
        if (lineItem.fItemPosition === fItemPosition) {
          return {
            ...lineItem,
            fQty: fQty,
            fUnitSellPrice: fUnitSellPrice
          };
        }
        return lineItem;
      });

      state.rQuote.rLineItems = updateProducts;
    },

    updateItemPrice(state, action) {
      const { fItemPosition, fQty, fUnitSellPrice } : SalesOrderLineItemFormValueProps = action.payload;

      const updateProducts = state.rQuote.rLineItems.map((lineItem) => {
        if (lineItem.fItemPosition === fItemPosition) {
          return {
            ...lineItem,
            fQty: fQty,
            fUnitSellPrice: fUnitSellPrice * 100,
          };
        }
        return lineItem;
      });

      state.rQuote.rLineItems = updateProducts;
    },

    updateItemNote(state, action) {
      const { fItemPosition, fNote } : SalesOrderLineItemFormValueProps = action.payload;

      const updateProducts = state.rQuote.rLineItems.map((lineItem) => {
        if (lineItem.fItemPosition === fItemPosition) {
          return {
            ...lineItem,
            fNote: fNote,
          };
        }
        return lineItem;
      });

      state.rQuote.rLineItems = updateProducts;
    },

    applyDiscount(state, action) {
      const rDiscount = action.payload;
      state.rQuote.rDiscount = rDiscount;
      state.rQuote.rTotal = state.rQuote.rSubtotal - rDiscount + state.rQuote.rTaxes;
    },

    getSalesOrderSuccess(state, action) {
      state.isLoading = false;
      state.salesOrderSearchResults = action.payload.elements;
    },

    resetSalesOrder(state) {
      state.salesOrderSearchResults = [];
    },
  },
});

// Reducer

// Reducer
export default slice.reducer;

// Actions
export const {
  getQuoteDetails,
  addToQuote,
  resetQuote,
  deleteLineItem,
  applyDiscount,
  updateQuantity,
  updateItemPrice,
  updateItemNote,
  resetSalesOrder
} = slice.actions;
// ----------------------------------------------------------------------


export function searchSalesOrders(criteria: ITableCriteria) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const payload = {
        query: `
          query salesOrders {
            salesOrders(
              criteria: {pageSize: ` +criteria.pageSize+ `, page: ` +criteria.page+ `,
                predicateGroups: [{
                  predicates: [{
                    column: SALES_ORDER_ID, operator: LIKE, data: "` +criteria.searchText+ `", dataType: "Number"
                  }],
                  operator: OR
                }]
            }) {
              totalElements
              pageSize
              currentPage
              elements{
                ...on SalesOrder{
                  organizationId
                  salesOrderId
                  partner{
                    partnerId
                    name
                  }
                }
              }
            }
          }        
        `
      };
      const response = await axiosGraphQL.post('/graphql', payload);
      dispatch(slice.actions.getSalesOrderSuccess(response.data.data.salesOrders));

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}







