import FEMoney from "../../@model/FEMoney";
import {
    FCashDrawer,
    FCashDrawerInput,
    FCashDrawerSession,
    FCashDrawerSessionInput,
    FDenomination,
    FDenominationCountInput
} from "../../generated/graphql";
import {DeepPartial} from "./fDeepPartial";
import {branchBEtoFE, FRHFBranch} from "./fOrganization";
import {formatDateTimeFromDateForJavaInstant} from "../../utils/formatTime";

export interface FRHFCashDrawer {
    rhfCashDrawerId: string;
    rhfAssignedAccountId: string;
    rhfAssignedStationId: string;
    rhfBranch: FRHFBranch;
    rhfCashDrawerDescription: string;
    rhfCashDrawerName: string;
    rhfStartingCash: FEMoney;
}

export interface FRHFCloseoutReport {
    rhfCashDrawerId: string;
    rhfCashDrawerName: string;
    rhfTimeStart: Date;
    rhfTimeEnd: Date;
    rhfStartingCash: FEMoney;
    rhfActualClosingCash: FEMoney;
    rhfActualClosingChecks: FEMoney;
    rhfCashKeptInDrawer: FEMoney;
    rhfSubmittedBy: string;
    rhfDenominationCounts: FRHFCloseoutReportDenominationCount[];
}

export enum CashTypeEnum {
    PAPER, COIN
}

export interface FRHFCloseoutReportDenominationCount {
    rhfMoneyID: FDenomination;
    rhfCashType: CashTypeEnum;
    rhfValue: FEMoney;
    rhfCount: number;
    rhfTotal: FEMoney;
}

export function cashDrawerBEtoFE(cashDrawer: DeepPartial<FCashDrawer | null | undefined>):FRHFCashDrawer {
    return {
        rhfCashDrawerId: cashDrawer?.cashDrawerId ?? "",
        rhfAssignedAccountId: cashDrawer?.assignedAccount?.accountId ?? "Anyone",
        rhfAssignedStationId: cashDrawer?.assignedStationId ?? "",
        rhfBranch: branchBEtoFE(cashDrawer?.branch),
        rhfCashDrawerDescription: cashDrawer?.cashDrawerDescription ?? "",
        rhfCashDrawerName: cashDrawer?.cashDrawerName ?? "",
        rhfStartingCash: FEMoney.fromMicros(cashDrawer?.startingCash ?? 0)
    }
}

export function cashDrawerFEtoBE(cashDrawer: FRHFCashDrawer):FCashDrawerInput {
    return {
        assignedAccountId: cashDrawer.rhfAssignedAccountId === "Anyone" ? null : cashDrawer.rhfAssignedAccountId,
        assignedStationId: cashDrawer.rhfAssignedStationId === "" ? null : cashDrawer.rhfAssignedStationId,
        branchId: cashDrawer.rhfBranch.rhfBranchId === "" ? "" : cashDrawer.rhfBranch.rhfBranchId,
        cashDrawerDescription: cashDrawer.rhfCashDrawerDescription === "" ? null : cashDrawer.rhfCashDrawerDescription,
        cashDrawerId: cashDrawer.rhfCashDrawerId === "" ? null : cashDrawer.rhfCashDrawerId,
        cashDrawerName: cashDrawer.rhfCashDrawerName === "" ? null : cashDrawer.rhfCashDrawerName,
        startingCash: cashDrawer.rhfStartingCash.toMicros()
    }
}

export function cashDrawerSessionFEtoBE(cashDrawerSession: FRHFCloseoutReport):FCashDrawerSessionInput {
    return {
        actualClosingCash: cashDrawerSession.rhfActualClosingCash.toMicros(),
        actualClosingChecks: cashDrawerSession.rhfActualClosingChecks.toMicros(),
        cashDrawerId: cashDrawerSession.rhfCashDrawerId,
        cashKeptInDrawer: cashDrawerSession.rhfCashKeptInDrawer.toMicros(),
        startingCash: cashDrawerSession.rhfStartingCash.toMicros(),
        timeStart: formatDateTimeFromDateForJavaInstant(cashDrawerSession.rhfTimeStart),
        timeEnd: formatDateTimeFromDateForJavaInstant(cashDrawerSession.rhfTimeEnd),
        denominationCounts: cashDrawerSession.rhfDenominationCounts
            .filter((denominationCount) => denominationCount.rhfCount > 0)
            .map(cashDrawerDenominationFEtoBE)
    }
}

export function cashDrawerSessionBEtoFE(cashDrawerSession: DeepPartial<FCashDrawerSession | null | undefined>):FRHFCloseoutReport {
    const submittedBy = cashDrawerSession?.reportSubmittedByAccount;
    const reportSubmittedBy = submittedBy ? submittedBy.firstName + ' ' + submittedBy.lastName : '';

    const nonzeroDenominationCountsMap: { [key in FDenomination]?: number } = {};
    for (let denominationCount of cashDrawerSession?.denominationCounts ?? []) {
        if (denominationCount && denominationCount.denomination) {
            nonzeroDenominationCountsMap[denominationCount.denomination] = denominationCount.count ?? 0;
        }
    }
    const denominationCounts = getDenominationCounts(nonzeroDenominationCountsMap);

    return {
        rhfCashDrawerId: cashDrawerSession?.cashDrawerId ?? "",
        rhfCashDrawerName: cashDrawerSession?.cashDrawerName ?? "",
        rhfTimeStart: new Date(cashDrawerSession?.timeStart ?? ""),
        rhfTimeEnd: new Date(cashDrawerSession?.timeEnd ?? ""),
        rhfStartingCash: FEMoney.fromMicros(cashDrawerSession?.startingCash ?? 0),
        rhfActualClosingCash: FEMoney.fromMicros(cashDrawerSession?.actualClosingCash ?? 0),
        rhfActualClosingChecks: FEMoney.fromMicros(cashDrawerSession?.actualClosingChecks ?? 0),
        rhfCashKeptInDrawer: FEMoney.fromMicros(cashDrawerSession?.cashKeptInDrawer ?? 0),
        rhfSubmittedBy: reportSubmittedBy,
        rhfDenominationCounts: denominationCounts
    }
}

export function cashDrawerDenominationFEtoBE(denomination: FRHFCloseoutReportDenominationCount): FDenominationCountInput {
    return {
        count: denomination.rhfCount,
        denomination: denomination.rhfMoneyID
    }
}

const getDenominationCounts = (nonzeroDenominationCountsMap: { [key in FDenomination]?: number }): FRHFCloseoutReportDenominationCount[] => {
    const paperDenominations: FDenomination[] = [
        FDenomination.Usd_1D, FDenomination.Usd_2D, FDenomination.Usd_5D, FDenomination.Usd_10D,
        FDenomination.Usd_20D, FDenomination.Usd_50D, FDenomination.Usd_100D
    ]
    const coinDenominations: FDenomination[] = [
        FDenomination.Usd_1C, FDenomination.Usd_5C, FDenomination.Usd_10C,
        FDenomination.Usd_25C, FDenomination.Usd_50C, FDenomination.Usd_100C
    ]
    const paperValues: number[] = [1, 2, 5, 10, 20, 50, 100];
    const coinValues: number[] = [0.01, 0.05, 0.1, 0.25, 0.5, 1];
    const denominationCounts: FRHFCloseoutReportDenominationCount[] = [];
    for (let i: number = 0; i < paperValues.length; i++) {
        const value = FEMoney.fromDollarDouble(paperValues[i]);
        const count = nonzeroDenominationCountsMap[paperDenominations[i]] ?? 0;
        denominationCounts.push({
            rhfMoneyID: paperDenominations[i],
            rhfCashType: CashTypeEnum.PAPER,
            rhfValue: value,
            rhfCount: count,
            rhfTotal: value.multiply(count)
        });
    }
    for (let i: number = 0; i < coinValues.length; i++) {
        const value = FEMoney.fromDollarDouble(coinValues[i]);
        const count = nonzeroDenominationCountsMap[coinDenominations[i]] ?? 0;
        denominationCounts.push({
            rhfMoneyID: coinDenominations[i],
            rhfCashType: CashTypeEnum.COIN,
            rhfValue: value,
            rhfCount: count,
            rhfTotal: value.multiply(count)
        });
    }
    return denominationCounts;
}

export const initialCashDrawerFormState: FRHFCashDrawer = {
    rhfCashDrawerId: "",
    rhfAssignedAccountId: "Anyone",
    rhfAssignedStationId: "",
    rhfBranch: branchBEtoFE(null),
    rhfCashDrawerDescription: "",
    rhfCashDrawerName: "",
    rhfStartingCash: FEMoney.ZERO
}

export const initialCloseoutReportFormState: FRHFCloseoutReport = {
    rhfCashDrawerId: "",
    rhfCashDrawerName: "",
    rhfActualClosingCash: FEMoney.ZERO,
    rhfActualClosingChecks: FEMoney.ZERO,
    rhfCashKeptInDrawer: FEMoney.ZERO,
    rhfTimeStart: new Date(),
    rhfTimeEnd: new Date(),
    rhfStartingCash: FEMoney.ZERO,
    rhfSubmittedBy: "",
    rhfDenominationCounts: getDenominationCounts({})
}