import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axiosGraphQL from '../../utils/axiosGraphQL';
import { IProductState, ICheckoutCartItem, IProductNewUpdate, IProduct, ProductSearchResults } from '../../@types/product';
import { ITableCriteria } from '../../@types/table';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState: IProductState = {
  isLoading: false,
  error: null,
  products: [],
  totalRecords: 0,
  product: null,
  checkout: {
    customer: null,
    activeStep: 0,
    cart: [],
    subtotal: 0,
    total: 0,
    discount: 0,
    shipping: 0,
    taxes: 0,
    billing: null,
    totalItems: 0,
  },
  productSearchResults: []
};

const slice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCTS
    getProductsSuccess(state, action) {
      state.isLoading = false;
      state.totalRecords = action.payload.totalElements;

      // hard coding the quantity because this is needed when adding items to the cart
      const updateProducts = action.payload.elements.map((product:IProduct) => {
        return {
          ...product,
          quantity: 1,
        };
      });

      state.products = updateProducts;
    },

    // GET PRODUCT
    getProductSuccess(state, action) {
      state.isLoading = false;
      state.product = action.payload;
    },

    // CREATE NEW PRODUCT
    createProductSuccess(state, action) {
      const newProduct = action.payload;
      state.isLoading = false;
      state.products = {
        ...state.products,
        [newProduct.productId]: newProduct,
      };
    },

    // CHECKOUT
    getCart(state, action) {
      const cart: ICheckoutCartItem[] = action.payload;

      const totalItems = sum(cart.map((product) => product.quantity));
      const subtotal = sum(cart.map((product) => product.unitPrice * product.quantity));
      state.checkout.cart = cart;
      state.checkout.discount = state.checkout.discount || 0;
      state.checkout.shipping = state.checkout.shipping || 0;
      state.checkout.billing = state.checkout.billing || null;
      state.checkout.subtotal = subtotal;

      // need to get this from organization
      state.checkout.taxes = 10;
      state.checkout.total = subtotal - state.checkout.discount + state.checkout.taxes;
      state.checkout.totalItems = totalItems;
    },

    addToCart(state, action) {
      const newProduct = action.payload;

      // this is needed to calculate the discount %
      newProduct.originalUnitPrice = newProduct.unitPrice;

      const isEmptyCart = !state.checkout.cart.length;

      if (isEmptyCart) {
        state.checkout.cart = [...state.checkout.cart, newProduct];
      } else {
        state.checkout.cart = state.checkout.cart.map((product) => {
          const isExisted = product.productId === newProduct.productId;

          if (isExisted) {
            return {
              ...product,
              quantity: product.quantity + 1,
            };
          }

          return product;
        });
      }
      state.checkout.cart = uniqBy([...state.checkout.cart, newProduct], 'productId');
      state.checkout.totalItems = sum(state.checkout.cart.map((product) => product.quantity));
    },

    deleteCart(state, action) {
      const updateCart = state.checkout.cart.filter((product) => product.productId !== action.payload);

      state.checkout.cart = updateCart;
    },

    resetCart(state) {
      state.checkout.customer = null;
      state.checkout.activeStep = 0;
      state.checkout.cart = [];
      state.checkout.total = 0;
      state.checkout.subtotal = 0;
      state.checkout.discount = 0;
      state.checkout.shipping = 0;
      state.checkout.taxes = 0;
      state.checkout.billing = null;
    },

    backStep(state) {
      state.checkout.activeStep -= 1;
    },

    nextStep(state) {
      state.checkout.activeStep += 1;
    },

    gotoStep(state, action) {
      const step = action.payload;
      state.checkout.activeStep = step;
    },

    increaseQuantity(state, action) {
      const productId = action.payload;

      const updateCart = state.checkout.cart.map((product) => {
        if (product.productId === productId) {
          return {
            ...product,
            quantity: product.quantity + 1,
          };
        }
        return product;
      });

      state.checkout.cart = updateCart;
    },

    decreaseQuantity(state, action) {
      const productId = action.payload;
      const updateCart = state.checkout.cart.map((product) => {
        if (product.productId === productId) {
          return {
            ...product,
            quantity: product.quantity - 1,
          };
        }
        return product;
      });

      state.checkout.cart = updateCart;
    },

    applyItemDiscount(state, action) {
      const { productId, overrideUnitPrice } = action.payload;

      const updateCart = state.checkout.cart.map((product) => {
        if (product.productId === productId) {
          return {
            ...product,
            unitDiscount: overrideUnitPrice / product.originalUnitPrice,
            unitPrice: overrideUnitPrice,
          };
        }
        return product;
      });

      state.checkout.cart = updateCart;
    },

    createBilling(state, action) {
      state.checkout.billing = action.payload;
    },

    applyDiscount(state, action) {
      const discount = action.payload;
      state.checkout.discount = discount;
      state.checkout.total = state.checkout.subtotal - discount + state.checkout.taxes;
    },

    applyShipping(state, action) {
      const shipping = action.payload;
      state.checkout.shipping = shipping;
      state.checkout.total = state.checkout.subtotal - state.checkout.discount + shipping + state.checkout.taxes;
    },

    resetProduct(state) {
      state.products = [];
      state.productSearchResults = [];
    },

    addCustomerToCart(state, action) {
      state.checkout.customer = action.payload;
    },

    // GET PRODUCTS
    getInventoryProductsSuccess(state, action) {
      state.isLoading = false;
      state.totalRecords = action.payload.totalElements;

      // hard coding the quantity because this is needed when adding items to the cart
      const updateProducts = action.payload.elements.map((product:ProductSearchResults) => {
        return {
          ...product,
          fullProductConcat: product.productId+ ' ' +product.name+ ' ' +product.sku+ ' ' +product.description
        };
      });

      state.productSearchResults = updateProducts;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  getCart,
  addToCart,
  resetCart,
  gotoStep,
  backStep,
  nextStep,
  deleteCart,
  createBilling,
  applyShipping,
  applyDiscount,
  increaseQuantity,
  decreaseQuantity,
  resetProduct,
  addCustomerToCart,
  applyItemDiscount,
} = slice.actions;

// ----------------------------------------------------------------------

export function getProducts() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const payload = {
        query: `
          query inventory {
            products(
              criteria: { pageSize:10, page:0 }
            ) {
              totalElements
              pageSize
              currentPage
              elements{
                ...on Product{
                  organizationId
                  productId
                  name
                  description
                }
              }
            }
          }        
        `
      };
      const response = await axiosGraphQL.post('/graphql', payload);
      dispatch(slice.actions.getProductsSuccess(response.data.data.products));

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getInventoryProducts(criteria: ITableCriteria) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const payload = {
        query: `
          query inventory {
            products(
              criteria: {pageSize: ` +criteria.pageSize+ `, page: ` +criteria.page+ ` }
            ) {
              totalElements
              pageSize
              currentPage
              elements{
                ...on Product{
                  organizationId
                  productId
                  name
                  description
                  unitPrice
                }
              }
            }
          }        
        `
      };
      const response = await axiosGraphQL.post('/graphql', payload);
      dispatch(slice.actions.getProductsSuccess(response.data.data.products));

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getProductDetails(productId: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const payload = {
        query: `
          query productDetails {
            product(productId: ` +productId+ `) {
              productId
              name
              description
            }
          }        
        `
      };
      const response = await axiosGraphQL.post('/graphql', payload);
      dispatch(slice.actions.getProductSuccess(response.data.data.product));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function saveOrUpdateProduct(product: IProductNewUpdate) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const payload = {
        query: `
          mutation saveOrUpdateProductMutation {
            saveProduct(product: {
              productId: ` +product.productId+ `
              name: "` +product.name+ `"
              description: "` +product.description+ `"
            }) {
              productId
              name
              description
            }
          }        
        `
      };

      const response = await axiosGraphQL.post('/graphql', payload);
      dispatch(slice.actions.createProductSuccess(response.data.data.saveProduct));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function searchInventoryProducts(criteria: ITableCriteria) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const payload = {
        query: `
          query inventory {
            products(
              criteria: {pageSize: ` +criteria.pageSize+ `, page: ` +criteria.page+ `,
                predicateGroups: [{
                  predicates: [{
                    column: NAME, operator: STR_CONTAINS, data: "` +criteria.searchText+ `", dataType: "String"
                  },{
                    column: SKU, operator: STR_CONTAINS, data: "` +criteria.searchText+ `", dataType: "String"
                  },{
                    column: DESCRIPTION, operator: STR_CONTAINS, data: "` +criteria.searchText+ `", dataType: "String"
                  }],
                  operator: OR
                }]
            }) {
              totalElements
              pageSize
              currentPage
              elements{
                ...on Product{
                  productId
                  name
                  description
                  sku
                  unitPrice
                }
              }
            }
          }        
        `
      };
      const response = await axiosGraphQL.post('/graphql', payload);
      dispatch(slice.actions.getInventoryProductsSuccess(response.data.data.products));

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}