import { format, getTime, formatDistanceToNow, parse, startOfDay, endOfDay } from 'date-fns';
import { zonedTimeToUtc, formatInTimeZone, utcToZonedTime } from 'date-fns-tz'

// ----------------------------------------------------------------------

type InputValue = Date | string | number | null | undefined;

/**
 *  May not want to use this... see fDateFromDB and fDateForDB below
 */
export function fDate(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'MM/dd/yy';

  return date ? format(new Date(date), fm) : '';
}

export function fDateFromDBtoDisplay(date: string | null | undefined, newFormat?: string) {
  const fm = newFormat || 'MM/dd/yy';

  if (date) {
    const d = parse(date, 'yyyy-MM-dd', new Date());
    return format(new Date(d), fm);
  }

  return '';
}

export function fDateToDisplay(date: Date | null | undefined, newFormat?: string) {
  const fm = newFormat || 'MM/dd/yy';

  if (date) {
    return format(date, fm);
  }

  return '';
}

// format comes as YYYY-MM-DD, reformat it to MM/DD/YYYY
export function fDateFromDB(date: string | undefined | null) {
  if (date) {
    const dateArray = date.split("-");
    return new Date(dateArray[1]+ "/" +dateArray[2]+ "/" +dateArray[0]);
  }

  return new Date();
}

// format comes as 2023-02-23T17:35:07.044081Z, reformat it to MM/DD/YYYY
export function fDateFromDBTimestamp(date: string | undefined) {
  const fm = 'MM/dd/yy';

  if (date) {
    const dateArray = date.split("-");
    const dayAndTime = dateArray[2];
    const dayOnly = dayAndTime.substring(0, dayAndTime.indexOf("T"));
    return format(new Date(dateArray[1]+ "/" +dayOnly+ "/" +dateArray[0]), fm);
  }

  return "";
}

export function fDateForDBStats(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'yyyy-MM-dd';

  return date ? format(new Date(date), fm) : '';
}

export function fDateForDB(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'MM/dd/yyyy';

  return date ? format(new Date(date), fm) : '';
}

export function fDateTime(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'MM/dd/yy p';

  return date ? format(new Date(date), fm) : '';
}

export function fTimestamp(date: InputValue) {
  return date ? getTime(new Date(date)) : '';
}

export function fToNow(date: InputValue) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
      })
    : '';
}





// format comes as YYYY-MM-DD, reformat it to MM/DD/YYYY
export function formatDateFromDB(date: string | undefined) {
  if (date) {
    const dateArray = date.split("-");
    return new Date(dateArray[1]+ "/" +dateArray[2]+ "/" +dateArray[0]);
  }

  return new Date();
}

export function formatDateForDB(date: InputValue, newFormat?: string) {
  if (date === null || date === undefined) return null;

  const fm = newFormat || 'MM/dd/yyyy';

  return date ? format(new Date(date), fm) : '';
}

export function formatDateForBELocalDate(date: InputValue, newFormat?: string) {
  if (date === null || date === undefined) return null;

  const fm = newFormat || 'yyyy-MM-dd';

  return date ? format(new Date(date), fm) : '';
}


// June 20th, 2023 8:45PM
// FE's MUI TextField where type = "datetime-local" will output 2023-06-20T20:45
// FE sends "2023-06-21T03:45:00.000Z"...
// ...and saves to DB as 2023-06-20 20:45:00-07
export function formatDateTimeForDB(date: InputValue): string | null {
  if (date === null || date === undefined || date === "") return null;

  // America/Los_Angeles
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const utcDate = zonedTimeToUtc(date, timeZone)

  return utcDate.toISOString();
}

// 2023-06-21T03:45:00Z (UTC time) value coming from BE/DB -> June 20th, 2023 8:45PM
// convert to 2023-06-20T20:45 for MUI TextField where type = "datetime-local"
export function formatDateTimeFromDB(date: InputValue): string | null {
  if (date === null || date === undefined || date === "") return null;

  if (date.toString() === 'Invalid Date') {
    return null;
  }

  // America/Los_Angeles
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const utcDate = zonedTimeToUtc(date, timeZone)

  return formatInTimeZone(utcDate, timeZone, "yyyy-MM-dd") + "T" + formatInTimeZone(utcDate, timeZone, "HH:mm")
}

export function formatDateTimeForDisplay(date: InputValue): string | null {
  if (date === null || date === undefined || date === "") return null;

  // America/Los_Angeles
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const utcDate = zonedTimeToUtc(date, timeZone)

  const formatForDateTimeLocal = formatInTimeZone(utcDate, timeZone, "MMM dd, yyyy h:mma")
  return formatForDateTimeLocal;
}

export function formatDateTimeForDisplayAsDate(date: InputValue): string | null {
  if (date === null || date === undefined || date === "") return null;

  // America/Los_Angeles
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const utcDate = zonedTimeToUtc(date, timeZone)

  return formatInTimeZone(utcDate, timeZone, "MMM dd, yyyy")
}

export type DateTimeProps = {
  date: Date;
  isStartOfDay?: boolean;
  isEndOfDay?: boolean;
}

export function formatDateTimeFromDateForDB({ date, isStartOfDay, isEndOfDay }: DateTimeProps): string | null {
  if (date === null || date === undefined) return null;

  let adjustedDate = new Date(date);
  if (isStartOfDay) {
    adjustedDate.setHours(0, 0, 0, 0);
  } else if (isEndOfDay) {
    adjustedDate.setHours(23, 59, 59, 999);
  }
  return formatSecondPrecisionDateTimeFromDateForDB(adjustedDate);
}

export function formatDateTimeFromDateForJavaInstant(date: Date): string | null {
  if (date === null || date === undefined) return null;
  return date.toISOString();
}

export function formatDateTimeFromCalendarDateForDB({ date }: DateTimeProps): string | null {
  if (date === null || date === undefined) return null;
  return formatSecondPrecisionDateTimeFromDateForDB(date);
}

export function nowTimeStamp() {
  return format(new Date(), 'MM/dd/yy hh:mm:ss');
}

function formatSecondPrecisionDateTimeFromDateForDB(date: Date): string {
  // date.toISOString() has format 2024-07-17T03:25:10.797Z
  // The replace-according-to-regex truncates it to 2024-07-17T03:25:10
  return date.toISOString().replace(/\.[0-9Z]{4}/, '');
}