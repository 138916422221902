import { createContext, useEffect, useReducer, useCallback } from 'react';
import {
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
  CognitoUserAttribute,
  AuthenticationDetails,
} from 'amazon-cognito-identity-js';
// utils
import axiosGraphQL from 'src/utils/axiosGraphQL';
import { setAxiosGraphQLSession } from './utils';

// routes
import { PATH_AUTH } from '../routes/paths';
//
import { ActionMapType, AuthStateType, AuthUserType, AWSCognitoContextType } from './types';
import { appUserBEtoFE } from 'src/@state/form/fAppUser';
import {useEnvContext} from "../utils/useEnvContext";

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

export enum Types {
  AUTH = 'AUTH',
  REGISTER = 'REGISTER',
  LOGOUT = 'LOGOUT',
}

type Payload = {
  [Types.AUTH]: {
    isAuthenticated: boolean;
    user: AuthUserType;
  };
  [Types.LOGOUT]: undefined;
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

// ----------------------------------------------------------------------

export const initialState: AuthStateType = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

export interface UserAttributeType {
  [key: string]: string;
}

export const reducer = (state: AuthStateType, action: ActionsType) => {
  if (action.type === Types.AUTH) {
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGOUT) {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

export const AuthContext = createContext<AWSCognitoContextType | null>(null);

type AuthProviderProps = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: AuthProviderProps) {

  const { REACT_APP_AWS_COGNITO_CLIENT_ID , REACT_APP_AWS_COGNITO_USER_POOL_ID} = useEnvContext();

  const userPool = new CognitoUserPool({
    UserPoolId: REACT_APP_AWS_COGNITO_USER_POOL_ID,
    ClientId: REACT_APP_AWS_COGNITO_CLIENT_ID,
  });

  const [state, dispatch] = useReducer(reducer, initialState);

  const getUserAttributes = useCallback(
    (currentUser: CognitoUser): Promise<UserAttributeType> =>
      new Promise((resolve, reject) => {
        currentUser.getUserAttributes((error, attributes) => {
          if (error) {
            reject(error);
            console.error(error);
          } else {
            const results: UserAttributeType = {};

            attributes?.forEach((attribute) => {
              results[attribute.Name] = attribute.Value;
            });

            resolve(results);
          }
        });
      }),
    []
  );

  const getSession = useCallback(
    () =>
      new Promise((resolve, reject) => {
        const cognitoUser = userPool.getCurrentUser();
        if (cognitoUser) {
          cognitoUser.getSession(
            async (error: Error | null, session: CognitoUserSession | null) => {
              if (error) {
                reject(error);
                console.error(error);
              }
              const attributes = await getUserAttributes(cognitoUser);

              if (session) {
                const token = session?.getIdToken().getJwtToken();
                sessionStorage.setItem('token', token);
                setAxiosGraphQLSession(token);
                
                const user = { 
                  ...cognitoUser, 
                  ...attributes, 
                  "organizationId": "",
                  "organizationName": "",
                  "organizationWebsiteUrl": "",
                  "organizationLogoUrl": "",
                  "accountId": "", 
                  "firstName": "", 
                  "lastName": "", 
                  "displayName": "", 
                  "email": "", 
                  "role": "", 
                  "branch": {},
                };

                const payload = {
                  query: `
                    query getUser{
                      currentAccount{
                        organization{
                          organizationId
                          name
                          websiteUrl
                          contactEmail
                          logo{
                              fileId
                              downloadUrl
                          }
                        }
                        accountId
                        firstName
                        lastName
                        email
                        cashDrawer {
                          organizationId
                          cashDrawerId
                        }
                        defaultBranch{
                          organizationId
                          branchId
                          name
                          defaultTaxRate
                          phoneNumber
                          primaryAddress{
                            addressId
                            streetLine1
                            streetLine2
                            city
                            state
                            postalCode
                          }
                          taxAccount{
                            taxAccountId
                            taxAgency
                            defaultTaxRate
                          }
                        }
                        role
                        encryptedPin
                        decryptedPINPlainText
                      }
                    } 
                  `
                };
                const response = await axiosGraphQL.post('/graphql', payload);

                if (response) {
                  // NOTE: this object of type FRHFAppUser can be referenced almost anywhere
                  // const { user } = useAuthContext();
                  // const appUser:FRHFAppUser = user as FRHFAppUser;
                  const appUser = appUserBEtoFE(cognitoUser, attributes, response?.data?.data?.currentAccount);
                  dispatch({
                    type: Types.AUTH,
                    payload: {
                      isAuthenticated: true,
                      user: appUser,
                    },
                  });

                //   const currectUserAccount = response?.data?.data?.currentAccount;
                //   user.organizationId = currectUserAccount?.organization.organizationId;
                //   user.organizationName = currectUserAccount?.organization.name;
                //   user.organizationWebsiteUrl = currectUserAccount?.organization.websiteUrl;
                //   user.organizationLogoUrl = currectUserAccount?.organization.logo?.downloadUrl;
                //   user.accountId = currectUserAccount?.accountId;
                //   user.firstName = currectUserAccount?.firstName;
                //   user.lastName = currectUserAccount?.lastName;
                //   user.displayName = currectUserAccount?.firstName + " " + currectUserAccount?.lastName;
                //   user.email = currectUserAccount?.email;

                //   if (currectUserAccount.defaultBranch) {
                //     user.branch = {
                //       "branchId" : currectUserAccount.defaultBranch.branchId,
                //       "branchName" : currectUserAccount.defaultBranch.name,
                //       "defaultTaxRate" : currectUserAccount.defaultBranch.defaultTaxRate,
                //       "phoneNumber" : currectUserAccount.defaultBranch.phoneNumber,
                //       "primaryAddress" : currectUserAccount.defaultBranch.primaryAddress,
                //     }
                //   }
                //   // TODO: get from response (aka user settings)
                //   user.role = 'admin';
                
              
                }

                resolve({
                  cognitoUser,
                  session,
                  headers: {
                    Authorization: token,
                  },
                });
  
                // dispatch({
                //   type: Types.AUTH,
                //   payload: {
                //     isAuthenticated: true,
                //     user: user,
                //   },
                // });
              } else {
                dispatch({
                  type: Types.AUTH,
                  payload: {
                    isAuthenticated: false,
                    user: null,
                  },
                });
              }
            }
          );
        } else {
          dispatch({
            type: Types.AUTH,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      }),
    [getUserAttributes]
  );

  const initialize = useCallback(async () => {
    try {
      await getSession();
    } catch {
      dispatch({
        type: Types.AUTH,
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  }, [getSession]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // We make sure to handle the user update here, but return the resolve value in order for our components to be
  // able to chain additional `.then()` logic. Additionally, we `.catch` the error and "enhance it" by providing
  // a message that our React components can use.

  // LOGIN
  const login = useCallback(
    (email: string, password: string) =>
      new Promise((resolve, reject) => {
        const userData = new CognitoUser({
          Username: email,
          Pool: userPool,
        });

        const authDetails = new AuthenticationDetails({
          Username: email,
          Password: password,
        });

        userData.authenticateUser(authDetails, {
          onSuccess: (result) => {
            getSession();
            resolve(result);

          },
          onFailure: (error) => {
            reject(error);
          },
        });
      }),
    [getSession]
  );

  // REGISTER
  const register = useCallback(
    (email: string, password: string, firstName: string, lastName: string) =>
      new Promise((resolve, reject) => {
        const newAttributes = [
          new CognitoUserAttribute({
            Name: 'email',
            Value: email,
          }),
          new CognitoUserAttribute({
            Name: 'name',
            Value: `${firstName} ${lastName}`,
          }),
        ];

        userPool.signUp(email, password, newAttributes, [], async (error) => {
          if (error) {
            reject(error);
            console.error(error);
            return;
          }

          resolve(undefined);
          window.location.href = PATH_AUTH.login;
        });
      }),
    []
  );

  // LOGOUT
  const logout = () => {
    const cognitoUser = userPool.getCurrentUser();

    if (cognitoUser) {
      localStorage.removeItem('accessToken');
      sessionStorage.removeItem('token');

      cognitoUser.signOut();
      dispatch({
        type: Types.LOGOUT,
      });
    }
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'cognito',
        login,
        loginWithGoogle: () => {},
        loginWithGithub: () => {},
        loginWithTwitter: () => {},
        register,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
