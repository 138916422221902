// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------
const ROOTS_ERP = '/erp';

export const PATH_ERP = {
  root: ROOTS_ERP,
  dashboard: {
    home: path(ROOTS_ERP, '/dashboard/home'),
  },
  organization: {
    root: path(ROOTS_ERP, '/org/main'),
    profile: path(ROOTS_ERP, '/org/profile'),
    category: (name: string) => path(ROOTS_ERP, `/category/${name}`),
  },
  pricing: {
    new: path(ROOTS_ERP, '/pricing/new'),
    edit: (id: string) => path(ROOTS_ERP, `/pricing/${id}/edit`),
  },
  user: {
    profile: path(ROOTS_ERP, '/user/profile'),
  },
  ims: {
    root: path(ROOTS_ERP, '/ims'),
    list: path(ROOTS_ERP, '/ims/list'),
    new: path(ROOTS_ERP, '/ims/new'),
    edit: (id: string, branchId: string) => path(ROOTS_ERP, `/ims/${id}/edit/${branchId}`),
  },
  vendor: {
    root: path(ROOTS_ERP, '/vendor'),
    list: path(ROOTS_ERP, '/vendor/list'),
    new: path(ROOTS_ERP, '/vendor/new'),
    edit: (id: string) => path(ROOTS_ERP, `/vendor/${id}/edit`),
    dibPriceUpdates: path(ROOTS_ERP, '/vendor/dib/priceUpdates'),
    profile: (id: string) => path(ROOTS_ERP, `/vendor/${id}/profile`),
  },
  customer: {
    root: path(ROOTS_ERP, '/customer'),
    list: path(ROOTS_ERP, '/customer/list'),
    new: path(ROOTS_ERP, '/customer/new'),
    edit: (id: string) => path(ROOTS_ERP, `/customer/${id}/edit`),
    profile: (id: string) => path(ROOTS_ERP, `/customer/${id}/profile`),
  },
  job: {
    root: path(ROOTS_ERP, '/job'),
    // list: (customerId: string) => path(ROOTS_ERP, `/job/${customerId}/list`),
    new: (customerId: string) => path(ROOTS_ERP, `/job/${customerId}/new`),
    edit: (customerId: string, jobId: string) => path(ROOTS_ERP, `/job/${customerId}/${jobId}/edit`),
  },
  po: {
    root: path(ROOTS_ERP, '/po'),
    list: path(ROOTS_ERP, '/po/list'),
    new: path(ROOTS_ERP, '/po/new/'),
    newFromSpecialOrder: (salesOrderId:string, createPOOption:string) => path(ROOTS_ERP, `/po/new/special/order/${salesOrderId}/${createPOOption}`),
    smartNew: (branchId:string, vendorId:string, categoryId:string) => path(ROOTS_ERP, `/po/smart/new/branch/${branchId}/vendor/${vendorId}/category/${categoryId}`),
    smartOrder: path(ROOTS_ERP, '/po/smart'),
    pdf: (id: string) => path(ROOTS_ERP, `/po/${id}/pdf`),    
    view: (id: string) => path(ROOTS_ERP, `/po/${id}/view`),
    edit: (id: string) => path(ROOTS_ERP, `/po/${id}/edit`),
  },
  receiving: {
    root: path(ROOTS_ERP, '/receiving'),
    list: path(ROOTS_ERP, '/receiving/list'),
    new: path(ROOTS_ERP, '/receiving/new'),
    view: (id: string) => path(ROOTS_ERP, `/receiving/${id}/view`),
    edit: (id: string) => path(ROOTS_ERP, `/receiving/${id}/edit`),
    po: (id: string) => path(ROOTS_ERP, `/receiving/po/${id}`),
  },
  invoice: {
    root: path(ROOTS_ERP, '/invoice'),
    list: path(ROOTS_ERP, '/invoice/list'),
    new_payable: path(ROOTS_ERP, '/invoice/payable/new'),
    edit_payable: (id: string) => path(ROOTS_ERP, `/invoice/payable/edit/${id}`),
    view_payable: (id: string) => path(ROOTS_ERP, `/invoice/payable/view/${id}`),
    pdf: (id: string) => path(ROOTS_ERP, `/invoice/pdf/${id}`),
    po: (id: string) => path(ROOTS_ERP, `/invoice/po/${id}`),

    new_receivable: path(ROOTS_ERP, '/invoice/receivable/new'),
    edit_receivable: (id: string) => path(ROOTS_ERP, `/invoice/receivable/edit/${id}`),
    view_receivable: (id: string) => path(ROOTS_ERP, `/invoice/receivable/view/${id}`),
    so: (id: string) => path(ROOTS_ERP, `/invoice/so/${id}`),
    // delivery: (id: string, deliveryId: string) => path(ROOTS_ERP, `/invoice/so/${id}/delivery/${deliveryId}`),
    checks: path(ROOTS_ERP, `/invoice/checks`),
  },
  crm: {
    root: path(ROOTS_ERP, '/crm'),
    profile: (id: string) => path(ROOTS_ERP, `/crm/${id}/profile`),
    account: (id: string) => path(ROOTS_ERP, `/crm/${id}/account`),
    list: path(ROOTS_ERP, '/crm/list'),
    new: path(ROOTS_ERP, '/crm/new'),
    edit: (id: string) => path(ROOTS_ERP, `/crm/${id}/edit`),
  },
  so: {
    root: path(ROOTS_ERP, '/so'),
    list: path(ROOTS_ERP, '/so/list'),
    new: path(ROOTS_ERP, '/so/new'),
    edit: (id: string) => path(ROOTS_ERP, `/so/${id}/edit`),
    deliver: (id: string) => path(ROOTS_ERP, `/so/${id}/deliver`),
    details: (id: string) => path(ROOTS_ERP, `/so/${id}/details`),
    pdf: (id: string) => path(ROOTS_ERP, `/so/${id}/pdf`),
  },
  cashdrawer: {
    root: path(ROOTS_ERP, '/cashdrawer'),
    drawerList: path(ROOTS_ERP, '/cashdrawer/drawer/list'),
    sessionList: path(ROOTS_ERP, '/cashdrawer/session/list'),
    sessionCloseout: path(ROOTS_ERP, '/cashdrawer/session/closeout'),
    sessionEdit: (id: string) => path(ROOTS_ERP, `/cashdrawer/session/${id}/edit`),
    sessionView: (id: string) => path(ROOTS_ERP, `/cashdrawer/session/${id}/view`),
    sessionPdf: (id: string) => path(ROOTS_ERP, `/cashdrawer/session/${id}/pdf`),
  },
  returns: {
    root: path(ROOTS_ERP, '/returns'),
    list: path(ROOTS_ERP, '/returns/list'),
    details: (id: string) => path(ROOTS_ERP, `/returns/${id}/details`),
    pdf: (id: string) => path(ROOTS_ERP, `/returns/${id}/pdf`),
  },
  workflow: {
    entity: (id: string, tab: string, specialOrder: boolean) => path(ROOTS_ERP, `/workflow/entity/${id}/${tab}/${specialOrder}`),
    entityWithReceipt: (inventoryReceiptId: string, id: string, tab: string, specialOrder: boolean) => path(ROOTS_ERP, `/workflow/entity/${id}/${tab}/${specialOrder}/${inventoryReceiptId}`),
  },
  shop: {
    root: path(ROOTS_ERP, '/shop'),
  },
  checkout: {
    root: path(ROOTS_ERP, '/checkout'),
    quote: (salesOrderId: string) => path(ROOTS_ERP, `/checkout/cart/so/${salesOrderId}`),
    delivery: (deliveryId: string) => path(ROOTS_ERP, `/checkout/cart/del/${deliveryId}`),
    invoice: (invoiceId: string) => path(ROOTS_ERP, `/checkout/cart/inv/${invoiceId}`),
  },
  delivery: {
    root: path(ROOTS_ERP, '/delivery'),
    list: path(ROOTS_ERP, '/delivery/list'),
    new: path(ROOTS_ERP, '/delivery/new'),
  },
};

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  fileManager: path(ROOTS_DASHBOARD, '/files-manager'),
  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
  blank: path(ROOTS_DASHBOARD, '/blank'),
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking'),
    file: path(ROOTS_DASHBOARD, '/file'),
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all'),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    view: (name: string) => path(ROOTS_DASHBOARD, `/chat/${name}`),
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    edit: (name: string) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    new: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    view: (name: string) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}`),
    edit: (name: string) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    demoView: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
  },
  invoice: {
    root: path(ROOTS_DASHBOARD, '/invoice'),
    list: path(ROOTS_DASHBOARD, '/invoice/list'),
    new: path(ROOTS_DASHBOARD, '/invoice/new'),
    view: (id: string) => path(ROOTS_DASHBOARD, `/invoice/${id}`),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/invoice/${id}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit'),
    demoView: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5'),
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    new: path(ROOTS_DASHBOARD, '/blog/new'),
    view: (title: string) => path(ROOTS_DASHBOARD, `/blog/post/${title}`),
    demoView: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
  },
};

export const PATH_DOCS = {
  root: 'https://docs.minimals.cc',
  changelog: 'https://docs.minimals.cc/changelog',
};

export const PATH_ZONE_ON_STORE = 'https://mui.com/store/items/zone-landing-page/';

export const PATH_MINIMAL_ON_STORE = 'https://mui.com/store/items/minimal-dashboard/';

export const PATH_FREE_VERSION = 'https://mui.com/store/items/minimal-dashboard-free/';

export const PATH_FIGMA_PREVIEW =
  'https://www.figma.com/file/OBEorYicjdbIT6P1YQTTK7/%5BPreview%5D-Minimal-Web.15.10.22?node-id=0%3A1';
