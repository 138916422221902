import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
import { createSlice } from '@reduxjs/toolkit';
// utils
import { IPOSCartBeforeCheckout, IPOSCheckoutState, IPOSCartLineItemFormValueProps } from 'src/@types/checkout';

// ----------------------------------------------------------------------

const initialState: IPOSCheckoutState = {
  isLoading: false,
  error: null,
  rActiveStep: 0,
  rSalesOrderIdFromQuote: null,
  rFulfillmentType: "",
  rSalesOrderId: null,
  rCustomer: null,
  rAddresses: [],
  rJob: null,
  rDelivery: null,
  rDeliveryInstructions: "",
  rBranchId: "",
  rTaxRate: 0,
  rCart: {
    rLineItems: [],
    rSubtotal: 0,
    rDiscount: 0,
    rShipping: 0,
    rTaxes: 0,
    rTotal: 0,
    rTotalItems: 0
  }
};

const slice = createSlice({
  name: 'checkoutReduxKey',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    initializeCheckout(state, action) {
      const co:IPOSCheckoutState = action.payload;

      state.rBranchId = co.rBranchId;
      // state.rDeliveryAddressId = co.rDeliveryAddressId;

      // cart
      const cart = co.rCart;

      const lineItems: IPOSCartLineItemFormValueProps[] = cart.rLineItems;

      state.rCart = cart;
      state.rCart.rShipping = state.rCart.rShipping || 0;
      state.rCart.rTotalItems = sum(lineItems.map((product) => product.fQty));

      // Check if we have subTotal and Total values from BE...
      if (state.rSalesOrderId == null) {
        state.rCart.rSubtotal = sum(lineItems.map((product) => product.fUnitSellPrice * product.fQty));
        state.rCart.rTotal = state.rCart.rSubtotal + state.rCart.rShipping + state.rCart.rTaxes;        
      } 
    },

    addItemToCart(state, action) {
      const newLineItem:IPOSCartLineItemFormValueProps = action.payload;
      state.rCart.rLineItems = [...state.rCart.rLineItems, newLineItem];
      state.rCart.rLineItems = uniqBy([...state.rCart.rLineItems, newLineItem], 'fItemPosition');
    },

    // IPOSCheckoutState as input
    addQuoteToCart(state, action) {
      const quote:IPOSCheckoutState = action.payload;

      state.rSalesOrderIdFromQuote = quote.rSalesOrderIdFromQuote;
      state.rCustomer = quote.rCustomer;
      state.rDelivery = quote.rDelivery;
      state.rJob = quote.rJob;
      state.rAddresses = quote.rAddresses;
      state.rCart = quote.rCart;
      state.rBranchId = quote.rBranchId;
      state.rTaxRate = quote.rTaxRate;
      state.rFulfillmentType = quote.rFulfillmentType;
      state.rDeliveryInstructions = quote.rDeliveryInstructions;
    },

    deleteLineItem(state, action) {
      const updateCart = state.rCart.rLineItems.filter((lineItem) => lineItem.fItemPosition !== action.payload);
      state.rCart.rLineItems = updateCart;
    },

    updateAllLineItems(state, action) {
      const allItems:IPOSCartLineItemFormValueProps[] = action.payload;
      state.rCart.rLineItems = allItems;
    },

    updateQuantity(state, action) {
      const { fProductId, fQty, fUnitSellPrice } : IPOSCartLineItemFormValueProps = action.payload;
      const updateProducts = state.rCart.rLineItems.map((lineItem) => {
        if (lineItem.fProductId === fProductId) {
          return {
            ...lineItem,
            fQty: fQty,
            fUnitSellPrice: fUnitSellPrice
          };
        }
        return lineItem;
      });

      state.rCart.rLineItems = updateProducts;
    },

    updateItemPrice(state, action) {
      const { fProductId, fQty, fUnitSellPrice } : IPOSCartLineItemFormValueProps = action.payload;
      const updateProducts = state.rCart.rLineItems.map((lineItem) => {
        if (lineItem.fProductId === fProductId) {
          return {
            ...lineItem,
            fQty: fQty,
            fUnitSellPrice: fUnitSellPrice * 100,
          };
        }
        return lineItem;
      });

      state.rCart.rLineItems = updateProducts;
    },

    resetCart(state) {
      state.rSalesOrderIdFromQuote = null;
      state.rSalesOrderId = null;
      state.rCustomer = null;
      state.rAddresses = [];
      state.rJob = null;
      state.rDelivery = null;
      state.rDeliveryInstructions = "";
      state.rBranchId = "";
      state.rTaxRate = 0;
      state.rActiveStep = 0;

      state.rCart.rLineItems = [];
      state.rCart.rTotal = 0;
      state.rCart.rSubtotal = 0;
      state.rCart.rDiscount = 0;
      state.rCart.rShipping = 0;
      state.rCart.rTaxes = 0;
    },

    backStep(state) {
      state.rActiveStep -= 1;
    },

    nextStep(state) {
      state.rActiveStep += 1;
    },

    gotoStep(state, action) {
      const step = action.payload;
      state.rActiveStep = step;
    },

    applyShipping(state, action) {
      const shipping = action.payload;
      state.rCart.rShipping = shipping;
    },

    applyTaxes(state, action) {
      state.rCart.rTaxes = action.payload;
    },

    addCustomerToCart(state, action) {
      state.rCustomer = action.payload;
      state.rAddresses = state.rCustomer?.addresses ?? [];
    },

    addJobToCart(state, action) {
      state.rJob = action.payload;
    },

    addAddressesToCart(state, action) {
      state.rAddresses = action.payload;
    },

    addDeliveryInstructionsToCart(state, action) {
      state.rDeliveryInstructions = action.payload;
    },

    addBranchAndTaxRateToCart(state, action) {
      const branchAndTaxRate = action.payload;
      state.rBranchId = branchAndTaxRate.branchId;
      state.rTaxRate = branchAndTaxRate.taxRate;
    },

    addDeliveryAddressToCart(state, action) {
      if (state.rDelivery) {
        state.rDelivery = {
          fDeliveryId: state.rDelivery.fDeliveryId,
          fDeliveryToAddressId: action.payload
        }
      } else {
        state.rDelivery = {
          fDeliveryId: "",
          fDeliveryToAddressId: action.payload
        }
      }
    },

    // all the values below are coming from BE
    updateCartBeforeCheckout(state, action) {
      const cartUpdates:IPOSCartBeforeCheckout = action.payload;
      state.rSalesOrderId = cartUpdates.rSalesOrderId;
      state.rTaxRate = cartUpdates.rTaxRate;
      state.rCart.rTaxes = cartUpdates.rTaxes;
      state.rCart.rShipping = cartUpdates.rShipping;
      state.rCart.rSubtotal = cartUpdates.rSubtotal;
      state.rCart.rTotal = cartUpdates.rTotal;
    }
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  initializeCheckout,
  addItemToCart,
  addQuoteToCart,
  resetCart,
  gotoStep,
  backStep,
  nextStep,
  updateAllLineItems,
  deleteLineItem,
  updateQuantity,
  updateItemPrice,
  applyShipping,
  applyTaxes,
  addCustomerToCart,
  addAddressesToCart,
  addJobToCart,
  addBranchAndTaxRateToCart,
  addDeliveryAddressToCart,
  updateCartBeforeCheckout,
  addDeliveryInstructionsToCart,
} = slice.actions;
