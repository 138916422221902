// routes
import { PATH_ERP } from '../../../routes/paths';
// components
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  blog: icon('ic_blog'),
  cart: icon('ic_cart'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
};

export const navConfig = [
  // ERP
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'Dashboard',
  //   items: [
  //     // Dashboard
  //     {
  //       title: '',
  //       path: PATH_DASHBOARD.general.app,
  //       icon: ICONS.dashboard,
  //     },
  //   ],
  // },
  {
    subheader: 'Dashboard',
    items: [
      // Dashboard
      {
        title: '',
        path: PATH_ERP.dashboard.home,
        icon: ICONS.dashboard,
      },
    ],
  },

  {
    subheader: 'POS',
    items: [
      // {
      //   title: 'Shop',
      //   path: PATH_ERP.shop.root,
      //   icon: ICONS.cart
      // },

      // Checkout
      {
        title: 'Checkout',
        path: PATH_ERP.checkout.root,
        icon: ICONS.cart
      },
      // Returns
      {
        title: 'Returns',
        path: PATH_ERP.returns.root,
        icon: ICONS.kanban
      },
    ],
  },  
    
  {
    subheader: 'SalesOrder',
    items: [
      // Sales
      {
        title: 'Sales',
        path: PATH_ERP.so.root,
        icon: ICONS.file
      },
      // Customer
      {
        title: 'Customer',
        path: PATH_ERP.customer.root,
        icon: ICONS.user
      },
    ],
  },  

  {
    subheader: 'BackOffice',
    items: [
      // IMS
      {
        title: 'Inventory',
        path: PATH_ERP.ims.root,
        icon: ICONS.ecommerce,
      },
      // Purchase Order
      {
        title: 'Purchasing',
        path: PATH_ERP.po.root,
        icon: ICONS.ecommerce
      },
      // Receiving
      {
        title: 'Receiving',
        path: PATH_ERP.receiving.root,
        icon: ICONS.booking
      },
      // Invoice
      {
        title: 'Invoice',
        path: PATH_ERP.invoice.root,
        icon: ICONS.invoice
      },
      // Vendor
      {
        title: 'Vendor',
        path: PATH_ERP.vendor.root,
        icon: ICONS.user
      },
    ],
  },

  {
    subheader: 'Fulfillment',
    items: [
      // Delivery
      {
        title: 'Delivery',
        path: PATH_ERP.delivery.root,
        icon: ICONS.booking
      },
    ],
  },  

  // // GENERAL
  // // ----------------------------------------------------------------------
  // {
  //   subheader: 'general',
  //   items: [
  //     { title: 'app', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
  //     { title: 'ecommerce', path: PATH_DASHBOARD.general.ecommerce, icon: ICONS.ecommerce },
  //     { title: 'analytics', path: PATH_DASHBOARD.general.analytics, icon: ICONS.analytics },
  //     { title: 'banking', path: PATH_DASHBOARD.general.banking, icon: ICONS.banking },
  //     { title: 'booking', path: PATH_DASHBOARD.general.booking, icon: ICONS.booking },
  //     { title: 'file', path: PATH_DASHBOARD.general.file, icon: ICONS.file },
  //   ],
  // },

  // // MANAGEMENT
  // // ----------------------------------------------------------------------
  // {
  //   subheader: 'management',
  //   items: [
  //     // USER
  //     {
  //       title: 'user',
  //       path: PATH_DASHBOARD.user.root,
  //       icon: ICONS.user,
  //       children: [
  //         { title: 'profile', path: PATH_DASHBOARD.user.profile },
  //         { title: 'cards', path: PATH_DASHBOARD.user.cards },
  //         { title: 'list', path: PATH_DASHBOARD.user.list },
  //         { title: 'create', path: PATH_DASHBOARD.user.new },
  //         { title: 'edit', path: PATH_DASHBOARD.user.demoEdit },
  //         { title: 'account', path: PATH_DASHBOARD.user.account },
  //       ],
  //     },

  //     // E-COMMERCE
  //     {
  //       title: 'ecommerce',
  //       path: PATH_DASHBOARD.eCommerce.root,
  //       icon: ICONS.cart,
  //       children: [
  //         { title: 'shop', path: PATH_DASHBOARD.eCommerce.shop },
  //         { title: 'product', path: PATH_DASHBOARD.eCommerce.demoView },
  //         { title: 'list', path: PATH_DASHBOARD.eCommerce.list },
  //         { title: 'create', path: PATH_DASHBOARD.eCommerce.new },
  //         { title: 'edit', path: PATH_DASHBOARD.eCommerce.demoEdit },
  //         { title: 'checkout', path: PATH_DASHBOARD.eCommerce.checkout },
  //       ],
  //     },

  //     // INVOICE
  //     {
  //       title: 'invoice',
  //       path: PATH_DASHBOARD.invoice.root,
  //       icon: ICONS.invoice,
  //       children: [
  //         { title: 'list', path: PATH_DASHBOARD.invoice.list },
  //         { title: 'details', path: PATH_DASHBOARD.invoice.demoView },
  //         { title: 'create', path: PATH_DASHBOARD.invoice.new },
  //         { title: 'edit', path: PATH_DASHBOARD.invoice.demoEdit },
  //       ],
  //     },

  //     // BLOG
  //     {
  //       title: 'blog',
  //       path: PATH_DASHBOARD.blog.root,
  //       icon: ICONS.blog,
  //       children: [
  //         { title: 'posts', path: PATH_DASHBOARD.blog.posts },
  //         { title: 'post', path: PATH_DASHBOARD.blog.demoView },
  //         { title: 'create', path: PATH_DASHBOARD.blog.new },
  //       ],
  //     },
  //     {
  //       title: 'File manager',
  //       path: PATH_DASHBOARD.fileManager,
  //       icon: ICONS.folder,
  //     },
  //   ],
  // },

  // // APP
  // // ----------------------------------------------------------------------
  // {
  //   subheader: 'app',
  //   items: [
  //     {
  //       title: 'mail',
  //       path: PATH_DASHBOARD.mail.root,
  //       icon: ICONS.mail,
  //       info: <Label color="error">+32</Label>,
  //     },
  //     {
  //       title: 'chat',
  //       path: PATH_DASHBOARD.chat.root,
  //       icon: ICONS.chat,
  //     },
  //     {
  //       title: 'calendar',
  //       path: PATH_DASHBOARD.calendar,
  //       icon: ICONS.calendar,
  //     },
  //     {
  //       title: 'kanban',
  //       path: PATH_DASHBOARD.kanban,
  //       icon: ICONS.kanban,
  //     },
  //   ],
  // },

];

export const posRoleNavConfig = [
  
  {
    subheader: 'Dashboard',
    items: [
      // Dashboard
      {
        title: '',
        path: PATH_ERP.dashboard.home,
        icon: ICONS.dashboard,
      },
    ],
  },

  {
    subheader: 'POS',
    items: [
      {
        title: 'Checkout',
        path: PATH_ERP.checkout.root,
        icon: ICONS.invoice
      },

    ],
  },  
    
  {
    subheader: 'SalesOrder',
    items: [
      // Sales
      {
        title: 'Sales',
        path: PATH_ERP.so.root,
        icon: ICONS.file
      },
      // Customer
      {
        title: 'Customer',
        path: PATH_ERP.customer.root,
        icon: ICONS.user
      },
    ],
  },  

];

