import { FPricingRule, FPricingRuleTypeEnum, FPriceDriverEnum, FPricingLevel, FEntityTypeEnum, FPricingLevelInput, FPricingRuleInput } from "src/generated/graphql";
import { DeepPartial } from "./fDeepPartial";
import { entityBEtoBE } from "./fOrganization";
import { FRHFPricingLevel, FRHFPricingRule } from "./fPricing";
import { FRHFProduct } from "./fProduct";

export interface FPricingLevelForm {
  rhfPricingLevel: FRHFPricingLevel;
  rhfPricingRuleType: FEntityTypeEnum;
  rhfPricingRule: FRHFPricingRule | null;
}

export const initialPricingLevelFormState: FPricingLevelForm = {
  rhfPricingLevel: pricingLevelBEtoFE(null),
  rhfPricingRuleType: FEntityTypeEnum.Organization,
  rhfPricingRule: null,
}

export interface FPricingLevelProductForm {
  rhfProduct: FRHFProduct;
  rhfPricingLevelProductRecords: FPricingLevelProductRecord[];
}

export interface FPricingLevelProductRecord {
  rhfPricingLevel: FRHFPricingLevel;
  rhfPrimaryPricingRule: FRHFPricingRule;
  rhfProductPricingRule: FRHFPricingRule;
}

export function initializePricingLevelFormState(rhfProduct: FRHFProduct): FPricingLevelProductForm {
  return {
    rhfProduct: rhfProduct,
    rhfPricingLevelProductRecords: [],
  }
}

export function pricingLevelBEtoFE(pricingRule:DeepPartial<FPricingLevel|null|undefined>):FRHFPricingLevel {
  return {
    rhfPricingLevelId: pricingRule?.pricingLevelId ?? "",
    rhfPrimaryPricingRuleId: pricingRule?.primaryPricingRule?.pricingRuleId ?? "",
    rhfName: pricingRule?.name ?? "",
    rhfDescription: pricingRule?.description ?? "",
  }
}

export function pricingLevelFEtoBE(pricingLevel:FRHFPricingLevel):FPricingLevelInput {
  return {
    pricingLevelId: pricingLevel.rhfPricingLevelId,
    name: pricingLevel.rhfName,
    description: pricingLevel?.rhfDescription ?? "",
  }
}

export function pricingRuleBEtoFE(pricingRule:DeepPartial<FPricingRule|null|undefined>):FRHFPricingRule {
  return {
    rhfPricingRuleId: pricingRule?.pricingRuleId ?? "",
    rhfPricingLevelId: pricingRule?.pricingLevelId ?? "",
    rhfType: pricingRule?.type ?? FPricingRuleTypeEnum.Discount,
    rhfAdjustmentAmount: pricingRule?.adjustmentAmount ?? 0,
    rhfDriver: pricingRule?.driver ?? FPriceDriverEnum.RetailPrice,
    rhfEntity: entityBEtoBE(pricingRule?.entity),
    rhfEntityName: pricingRule?.entity?.entityName ?? "",  
  }
}

export function pricingRuleFEtoBE(pricingRule:FRHFPricingRule):FPricingRuleInput {
  return {
    pricingRuleId: pricingRule?.rhfPricingRuleId ?? undefined,
    pricingLevelId: pricingRule?.rhfPricingLevelId ?? undefined,
    type: pricingRule?.rhfType ?? FPricingRuleTypeEnum.Discount,
    adjustmentAmount: pricingRule?.rhfAdjustmentAmount ?? 0,
    driver: pricingRule?.rhfDriver ?? FPriceDriverEnum.RetailPrice,
    entityId: pricingRule?.rhfEntity?.entityId ?? "",
    entityType: pricingRule?.rhfEntity?.entityType ?? FEntityTypeEnum.Product,
    entity: {
      entityId: pricingRule?.rhfEntity?.entityId ?? "",
      entityType: pricingRule?.rhfEntity?.entityType ?? FEntityTypeEnum.Product,
    }
  }
}
