import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import mailReducer from './slices/mail';
import chatReducer from './slices/chat';
import productReducer from './slices/product';
import calendarReducer from './slices/calendar';
import kanbanReducer from './slices/kanban';
import salesOrderReducer from './slices/salesOrder';
import receivingSlice from './slices/receiving';
import checkoutSlice from './slices/checkout';
import userProfileSlice from './slices/userProfile';

import fAPIErrorSlice from 'src/@state/redux/fAPIErrorSlice';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const salesOrderPersistConfig = {
  key: 'salesOrderReduxKey',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const purchaseOrderPersistConfig = {
  key: 'purchaseOrderReduxKey',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const inventoryReceiptPersistConfig = {
  key: 'inventoryReceiptReduxKey',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const checkoutPersistConfig = {
  key: 'checkoutReduxKey',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const userProfilePersistConfig = {
  key: 'userProfileReduxKey',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};






const apiErrorPersistConfig = {
  key: 'apiErrorSliceReduxKey',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy'],
};

const rootReducer = combineReducers({
  mail: mailReducer,
  chat: chatReducer,
  calendar: calendarReducer,
  kanban: kanbanReducer,
  product: persistReducer(productPersistConfig, productReducer),
  salesOrderReducer: persistReducer(salesOrderPersistConfig, salesOrderReducer),
  receivingReducer: persistReducer(inventoryReceiptPersistConfig, receivingSlice),
  checkoutReducer: persistReducer(checkoutPersistConfig, checkoutSlice),
  userProfileReducer: persistReducer(userProfilePersistConfig, userProfileSlice),


  apiErrorReducer: persistReducer(apiErrorPersistConfig, fAPIErrorSlice),
});

export { rootPersistConfig, rootReducer };
