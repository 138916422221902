import axiosGraphQLInstance from "./axiosGraphQL";

export function getS3FileType(downloadUrl:string) {
  let type = "";

  const urlOnly = downloadUrl.substring(0, downloadUrl.indexOf("?"))
  const extension = urlOnly.split('.').pop();

  if (extension === "pdf") {
    type = "application/pdf";
  } else if (extension === "png") {
    type = "image/png";
  } else if (extension === "jpeg") {
    type = "image/jpeg";
  } else if (extension === "jpg") {
    type = "image/jpg";
  }

  return type;
}

export function s3FileDownloadInNewTab(downloadUrl:string | null) {
  if (downloadUrl) { 
    const fileType = getS3FileType(downloadUrl);

    delete axiosGraphQLInstance.defaults.headers.common.Authorization;
    axiosGraphQLInstance.get(downloadUrl, { responseType: 'blob', headers: { 'Content-Type': 'application/octet-stream' } })
    .then(response => {
      const file = new Blob(
        [response.data], 
        {type: fileType}
      );
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, "_blank");
    })
  }
}

export function s3FileDownload(fileDownloadUrl:string | undefined) {
  if (fileDownloadUrl) {
    const link = document.createElement("a");
    link.href = fileDownloadUrl;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}