// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// locales
import ThemeLocalization from './locales';
// components
import { StyledChart } from './components/chart';
import SnackbarProvider from './components/snackbar';
import { ThemeSettings } from './components/settings';
import { MotionLazyContainer } from './components/animate';

import { BrowserRouter } from 'react-router-dom';
// @mui
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';

// components
import { SettingsProvider } from './components/settings';
import ScrollToTop from './components/scroll-to-top';

// GraphQL Error handling 
import { createClient, dedupExchange, fetchExchange, mapExchange, Operation, OperationResult, Provider } from "urql";
import APIErrorContext from './@components/error/APIErrorContext';

// redux
import { useDispatch } from 'src/redux/store';
import { addErrorMessages } from 'src/@state/redux/fAPIErrorSlice';
import { useAuthContext } from './auth/useAuthContext';

// Microsoft clarity
import { clarity } from 'react-microsoft-clarity';

// ----------------------------------------------------------------------

export default function App() {
  const { logout } = useAuthContext();

  const dispatch = useDispatch();

  function getBearerToken() {
    return sessionStorage.getItem('token');
  }

  // Start seeing data on the Clarity dashboard with your id
  const hostname = window.location.hostname;
  if (hostname.includes('app.getflitch.com')) {
    clarity.init('mh21iydlux');
  } else if (hostname.includes('demo.getflitch.com')) {
    clarity.init('mh2198bocw');
  } else if (hostname.includes('staging.getflitch.com')) {
    clarity.init('mh210j9u2w');
  } else if (hostname.includes('dev.getflitch.com')) {
    clarity.init("mgupj12m0o");
  }

  const urqlClient = createClient({
    url: '/graphql',
    exchanges: [dedupExchange,
      mapExchange({
        onResult(result: OperationResult): Promise<OperationResult> | OperationResult | void {
          // console.log("onResult Logged", result);
        },
        onOperation(operation: Operation): Promise<Operation> | Operation | void {
          // console.log("onOperation Logged", operation);
        },
        onError(error, operation) {
          try {
            // get more info on the error for proper handline
            console.log("URQL Error: Name [" +error.name+ " ] Message [" +error.message+ "]");
            console.log(operation);
            console.log(error.networkError)
            console.log(error.graphQLErrors)
            console.log(error.response)
          } catch (e) { }
          
          // add error message to redux, to be triggered in APIErrorContext.tsx
          const errorMessages:string[] = error.graphQLErrors.map((e: any) => e.message);
          dispatch(addErrorMessages(errorMessages));

          if (error.networkError?.message === 'Unauthorized') {
            logout();
          }
        }
      }),
    fetchExchange],
    fetchOptions: () => {
      const token = getBearerToken();
      return {
        headers: { authorization: token ? `Bearer ${token}` : '' },
      };
    },
  });

  return (
    <Provider value={urqlClient}>
      <APIErrorContext />
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <SettingsProvider>
          <BrowserRouter>
            <ScrollToTop />
            <MotionLazyContainer>
              <ThemeProvider>
                <ThemeSettings>
                  <ThemeLocalization>
                    <SnackbarProvider>
                      <StyledChart />
                      <Router />
                    </SnackbarProvider>
                  </ThemeLocalization>
                </ThemeSettings>
              </ThemeProvider>
            </MotionLazyContainer>
          </BrowserRouter>
        </SettingsProvider>
      </LocalizationProvider>
    </Provider>
  );
}
