import { Navigate, useRoutes } from 'react-router-dom';
// auth
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
// layouts
import MainLayout from '../layouts/main';
import SimpleLayout from '../layouts/simple';
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
// config
import { PATH_AFTER_LOGIN } from '../config';
//
import {
  // Auth
  LoginPage,
  RegisterPage,
  VerifyCodePage,
  NewPasswordPage,
  ResetPasswordPage,
  //
  Page500,
  Page403,
  Page404,
  HomePage,
  FaqsPage,
  AboutPage,
  Contact,
  PricingPage,
  PaymentPage,
  ComingSoonPage,
  MaintenancePage,

  // ERP
  FDashboardHomePage,
  OrganizationProfilePage,
  
  FPricingLevelCreateEditPage,

  FUserProfilePage,
  
  FPOSCheckoutPage,

  FInventoryListPage,
  FInventoryCreateEditPage,

  FWorkflowEntityHeaderPage,

  FSalesOrderListPage,
  FSalesOrderDeliveryPage,
  FSalesOrderCreateEditPage,
  FSalesOrderViewDetailsPage,
  FSalesOrderPDFPage,

  FOrderReturnsListPage,
  FOrderReturnsViewDetailsPage,
  FOrderReturnsPDFPage,

  FPurchaseOrderListPage,
  FPurchaseOrderCreateFromSmartOrderPage,
  FPurchaseOrderCreateFromSpecialOrderPage,
  FPurchaseOrderCreateEditPage,
  FPurchaseOrderPDFPage,
  FPurchaseOrderViewDetailsPage,
  FPurchaseOrderSmartOrderPage,

  FInventoryReceiptListPage,
  FInventoryReceiptCreatePage,
  FInventoryReceiptEditPage,
  FInventoryReceiptFromPurchaseOrderPage,

  FInvoiceListPage,
  FInvoiceFromPurchaseOrderPage,
  FInvoicePayableCreateEditPage,
  FInvoicePayableViewDetailsPage,
  FInvoicePDFPage,

  FInvoiceFromSalesOrderPage,
  FInvoiceFromDeliveryPage,
  FInvoiceReceivableCreateEditPage,
  FInvoiceReceivableViewDetailsPage,
  FInvoicePrintCheckPage,

  FVendorListPage,
  FVendorCreateEditPage,
  FDoItBestPriceUpdateListPage,
  FVendorProfilePage,

  FCashDrawerListPage,
  FCashDrawerSessionListPage,
  FCashDrawerSessionCloseoutPage,
  FCashDrawerSessionViewPage,

  FCustomerListPage,
  FCustomerCreateEditPage,
  FCustomerProfilePage,
  
  FJobListPage,
  FJobCreateEditPage,

  AccountingConfigurationPage,

  FDeliveryListPage,
} from './elements';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
      ],
    },
    // Auth
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <RegisterPage />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <LoginPage /> },
        { path: 'register-unprotected', element: <RegisterPage /> },
        {
          element: <CompactLayout />,
          children: [
            { path: 'reset-password', element: <ResetPasswordPage /> },
            { path: 'new-password', element: <NewPasswordPage /> },
            { path: 'verify', element: <VerifyCodePage /> },
          ],
        },
      ],
    },

  // Compact Layout
    {
      path: 'form',
      element: (
        <AuthGuard>
          <CompactLayout />
        </AuthGuard>
      ),
      children: [
        { path: 'accounting/configuration/:id', element: <AccountingConfigurationPage /> },
        ],
    },

    // ERP
    {
      path: 'erp',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>        
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: 'dashboard',
          children: [
            { element: <Navigate to="/erp/dashboard/home" replace />, index: true },
            // { path: 'home', element: <FDashboardHomePage /> },
            { path: 'home', element: <FPOSCheckoutPage /> },
          ],
        },
        {
          path: 'org',
          children: [
            { element: <Navigate to="/erp/org/profile" replace />, index: true },
            { path: 'profile', element: <OrganizationProfilePage /> },
            { path: 'oauth/success/:tab', element: <OrganizationProfilePage /> },
          ],
        },
        {
          path: 'pricing',
          children: [
            { path: 'new', element: <FPricingLevelCreateEditPage /> },
            { path: ':id/edit', element: <FPricingLevelCreateEditPage /> },
          ],
        },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/erp/user/profile" replace />, index: true },
            { path: 'profile', element: <FUserProfilePage /> },
          ],
        },
        {
          path: 'ims',
          children: [
            { element: <Navigate to="/erp/ims/list" replace />, index: true },
            { path: 'list', element: <FInventoryListPage /> },
            { path: 'new', element: <FInventoryCreateEditPage /> },
            { path: ':id/edit/:branchId', element: <FInventoryCreateEditPage /> },
          ],
        },
        {
          path: 'vendor',
          children: [
            { element: <Navigate to="/erp/vendor/list" replace />, index: true },
            // { path: ':id/account', element: <VendorAccountPage /> },
            { path: 'list', element: <FVendorListPage /> },
            { path: 'new', element: <FVendorCreateEditPage /> },
            { path: ':id/edit', element: <FVendorCreateEditPage /> },
            { path: 'dib/priceUpdates', element: <FDoItBestPriceUpdateListPage /> },
            { path: ':id/profile', element: <FVendorProfilePage /> },
          ],
        },
        {
          path: 'customer',
          children: [
            { element: <Navigate to="/erp/customer/list" replace />, index: true },
            { path: 'list', element: <FCustomerListPage /> },
            { path: 'new', element: <FCustomerCreateEditPage /> },
            { path: ':id/edit', element: <FCustomerCreateEditPage /> },
            { path: ':id/profile', element: <FCustomerProfilePage /> },
          ],
        },
        {
          path: 'job',
          children: [
            { element: <Navigate to="/erp/job/:id/list" replace />, index: true },
            { path: ':customerId/list', element: <FJobListPage /> },
            { path: ':customerId/new', element: <FJobCreateEditPage /> },
            { path: ':customerId/:jobId/edit', element: <FJobCreateEditPage /> },
          ],
        },
        {
          path: 'po',
          children: [
            { element: <Navigate to="/erp/po/list" replace />, index: true },
            { path: 'list', element: <FPurchaseOrderListPage /> },
            { path: 'new', element: <FPurchaseOrderCreateEditPage /> },
            { path: 'smart/new/branch/:branchId/vendor/:vendorId/category/:categoryId', element: <FPurchaseOrderCreateFromSmartOrderPage /> },
            { path: 'smart', element: <FPurchaseOrderSmartOrderPage /> },
            { path: 'new/special/order/:salesOrderId/:createPOOption', element: <FPurchaseOrderCreateFromSpecialOrderPage /> },
            { path: ':id/pdf', element: <FPurchaseOrderPDFPage /> },
            { path: ':id/view', element: <FPurchaseOrderViewDetailsPage /> },
            { path: ':id/edit', element: <FPurchaseOrderCreateEditPage /> },
          ],
        },
        {
          path: 'cashdrawer',
          children: [
            { element: <Navigate to="/erp/cashdrawer/list" replace />, index: true },
            { path: 'session/list', element: <FCashDrawerSessionListPage /> },
            { path: 'session/closeout', element: <FCashDrawerSessionCloseoutPage /> },
            { path: 'session/:id/view', element: <FCashDrawerSessionViewPage /> },
            { path: 'drawer/list', element: <FCashDrawerListPage /> },
          ],
        },
        {
          path: 'receiving',
          children: [
            { element: <Navigate to="/erp/receiving/list" replace />, index: true },
            { path: 'list', element: <FInventoryReceiptListPage /> },
            { path: 'new', element: <FInventoryReceiptCreatePage /> },
            { path: ':id/edit', element: <FInventoryReceiptEditPage /> },
            { path: 'po/:id', element: <FInventoryReceiptFromPurchaseOrderPage /> },
          ],
        },
        {
          path: 'invoice',
          children: [
            { element: <Navigate to="/erp/invoice/list" replace />, index: true },
            { path: 'list', element: <FInvoiceListPage /> },

            { path: 'payable/new', element: <FInvoicePayableCreateEditPage /> },
            { path: 'payable/edit/:id', element: <FInvoicePayableCreateEditPage /> },
            { path: 'payable/view/:id', element: <FInvoicePayableViewDetailsPage /> },
            { path: 'pdf/:id', element: <FInvoicePDFPage /> },
            { path: 'po/:id', element: <FInvoiceFromPurchaseOrderPage /> },

            { path: 'receivable/new', element: <FInvoiceReceivableCreateEditPage /> },
            { path: 'receivable/edit/:id', element: <FInvoiceReceivableCreateEditPage /> },
            { path: 'receivable/view/:id', element: <FInvoiceReceivableViewDetailsPage /> },
            { path: 'so/:id', element: <FInvoiceFromSalesOrderPage /> },
            { path: 'so/:id/delivery/:deliveryId', element: <FInvoiceFromDeliveryPage /> },
            { path: 'checks', element: <FInvoicePrintCheckPage /> },
          ],
        },
        {
          path: 'so',
          children: [
            { element: <Navigate to="/erp/so/list" replace />, index: true },
            { path: 'list', element: <FSalesOrderListPage /> },
            { path: 'new', element: <FSalesOrderCreateEditPage /> },
            { path: ':id/details', element: <FSalesOrderViewDetailsPage /> },
            { path: ':id/edit', element: <FSalesOrderCreateEditPage /> },
            { path: ':id/deliver', element: <FSalesOrderDeliveryPage /> },
            { path: ':id/pdf', element: <FSalesOrderPDFPage /> },
          ],
        },
        {
          path: 'returns',
          children: [
            { element: <Navigate to="/erp/returns/list" replace />, index: true },
            { path: 'list', element: <FOrderReturnsListPage /> },
            { path: ':id/details', element: <FOrderReturnsViewDetailsPage /> },
            { path: ':id/pdf', element: <FOrderReturnsPDFPage /> },
          ],
        },
        {
          path: 'workflow',
          children: [
            { path: 'entity/:id/:tab/:specialOrder/', element: <FWorkflowEntityHeaderPage /> },
            { path: 'entity/:id/:tab/:specialOrder/:inventoryReceiptId', element: <FWorkflowEntityHeaderPage /> },
          ]
        },
        {
          path: 'checkout',
          children: [
            { element: <Navigate to="/erp/checkout/cart" replace />, index: true },
            { path: 'cart', element: <FPOSCheckoutPage /> },
            { path: 'cart/so/:salesOrderId', element: <FPOSCheckoutPage /> },
            { path: 'cart/del/:deliveryId', element: <FPOSCheckoutPage /> },
            { path: 'cart/inv/:invoiceId', element: <FPOSCheckoutPage /> },
          ],
        },
        {
          path: 'delivery',
          children: [
            { element: <Navigate to="/erp/delivery/list" replace />, index: true },
            { path: 'list', element: <FDeliveryListPage /> },
          ],
        },
      ],
    },
    
    // Dashboard - DO NOT REMOVE
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
      ]
    },

    // Main Routes
    {
      element: <MainLayout />,
      children: [
        { element: <HomePage />, index: true },
        { path: 'about-us', element: <AboutPage /> },
        { path: 'contact-us', element: <Contact /> },
        { path: 'faqs', element: <FaqsPage /> },
      ],
    },
    {
      element: <SimpleLayout />,
      children: [
        { path: 'pricing', element: <PricingPage /> },
        { path: 'payment', element: <PaymentPage /> },
      ],
    },
    {
      element: <CompactLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoonPage /> },
        { path: 'maintenance', element: <MaintenancePage /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
