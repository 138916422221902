import FEMoney from "src/@model/FEMoney";
import FEPercentage from "src/@model/FEPercentage";
import {FBillingLevel, FPostingType} from "../../generated/graphql";

// Charge Account
export interface FRHFChargeAccount {
  rhfChargeAccountId: string;
  rhfPartnerId: string;
  rhfEnabled: boolean;
  rhfCreditLimit: FEMoney;
  rhfDueDate: number;
  rhfFinanceChargeRate: FEPercentage;
  rhfStatementDeliveryMail: boolean;
  rhfStatementDeliveryEMail: boolean;
  rhfPostingType: FPostingType;
  rhfBillingLevel: FBillingLevel;
}

export const initialChargeAccountState: FRHFChargeAccount = {
  rhfChargeAccountId: "",
  rhfPartnerId: "",
  rhfEnabled: false,
  rhfCreditLimit: FEMoney.ZERO,
  rhfDueDate: 30, 
  rhfFinanceChargeRate: FEPercentage.ZERO,
  rhfStatementDeliveryMail: false,
  rhfStatementDeliveryEMail: true,
  rhfPostingType: FPostingType.BalanceForward,
  rhfBillingLevel: FBillingLevel.AccountLevel
}

// Statement
export interface FRHFStatement {
  rhfStatementId: string;
  rhfChargeAccountId: string;
  rhfStatementFileId: string;
  rhfStartDate: string;
  rhfEndDate: string;
  rhfPreviousBalance: FEMoney;
  rhfBeginningBalance: FEMoney;
  rhfCharge: FEMoney;
  rhfCredit: FEMoney;
  rhfPayment: FEMoney;
  rhfFinanceCharge: FEMoney;
  rhfBalance30: FEMoney;
  rhfBalance60: FEMoney;
  rhfBalance90: FEMoney;
  rhfBalance90Plus: FEMoney;
  rhfRemainingBalance: FEMoney;
}